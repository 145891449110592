import { useDispatch, useSelector } from "react-redux";
import styles from "./OfflineSuppliersStyles.module.scss";
import { useEffect, useRef, useState } from "react";
import { setTab } from "../../../reducerSlices/selectedTabSlice";
import PageSearch from "../../../common/components/pageSearch/PageSearch";
import {
  constants,
  offlineSuppliers,
} from "../../../common/constants/constants";
import { ISortOption } from "../../../models/IAdminUserListingProps";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import { CancelToken } from "axios";
import { useAxios } from "../../../services/useAxios";
import {
  APIMethods,
  adminEndPoints,
} from "../../../common/constants/urlConstants";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { useCancelToken } from "../../../hooks/useCacelToken";
import OfflineSupplierCard from "./components/OfflineSupplierCard";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import { setPage } from "../../../reducerSlices/selectedPageSlice";
import { EmptyContainer } from "../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg"
import { resetToastStatus } from "../../../reducerSlices/toastStatusSlice";


const OfflineSuppliersList = () => {
  const dispatch = useDispatch();
  
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.offline_suppliers ?? 0
  );
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.offlineSuppliersPageNumber ?? 1
  );

  const { toastStatus } = useSelector((state: any) => state);
  const [activeTabId, setActiveTabId] = useState(selectedTab);
  const [activeTabData, setActiveTabData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [prevSelected, setPrevSelected] = useState(-1);
  const [showSort, setShowSort] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let { cancelTokenRef, createCancelToken } = useCancelToken();
  const sortContainerRef = useRef<HTMLDivElement>(null);
  const searchCustomStyles = {
    top: "0px",
  };

  useEffect(() => {
    if (toastStatus?.message) {
      // Reset toast status after 5 seconds
      const timeout = setTimeout(() => {
        dispatch(resetToastStatus());
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [toastStatus, dispatch]);

  //this function is used to get the selected tab payload to fetch list
  const getType = () => {
    const activeTabObj = offlineSuppliers?.tabsList?.find(
      (each: any) => each?.id === activeTabId
    );
    return activeTabObj?.value ?? "";
  };

  //api to fetch offline suppliers list
  const getOfflineSuppliers = useAxios({
    axiosParams: {
      url: `${
        adminEndPoints.getOfflineSuppliersList
      }?page=${currentPage}&type=${getType()}`,
    },
    method: APIMethods.get,
  });
  const getOfflineSuppliersListApi = async (
    searchTerm?: string,
    cancelToken?: CancelToken
  ) => {
    let response: any;
    dispatch(updateIsLoading(true));
    if (searchTerm && searchTerm?.length > 0) {
      response = await getOfflineSuppliers?.fetchData({
        axiosParams: {
          url: `${
            adminEndPoints.getOfflineSuppliersList
          }?page=${currentPage}&type=${getType()}&search=${searchTerm}`,
          cancelToken,
        },
        method: APIMethods.get,
      });
    } else {
      response = await getOfflineSuppliers?.fetchData();
    }
    if (response && response?.status === "SUCCESS") {

      setActiveTabData(response?.data?.offline_suppliers ?? []);
      setTotalCount(response?.data?.count ?? 0);
    }
    dispatch(updateIsLoading(false));
  };
  const selectTab = (index: number) => {
    dispatch(setTab({ type: "offline_suppliers", index }));
    setActiveTabId(index);
    setCurrentPage(1);
  };
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    offlineSuppliers.sortOptions[id].onClick();
  };
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const handleSearch = (value: string, token?: CancelToken) => {
    setCurrentPage(1);
    setSearchValue(value);
    (value || value === "") && setActiveTabData([]);
    (value || value === "" ) && getOfflineSuppliersListApi(value, token);
  };
  const getAddress = (
    area: string,
    address: string,
    emirate: string,
    country: string
  ) => {
    const finalAddress: string[] = [];
    if (area) {
      finalAddress.push(area);
    }
    if (address) {
      finalAddress.push(address);
    }
    if (emirate) {
      finalAddress.push(emirate);
    }
    if (country) {
      finalAddress.push(country);
    }
    if (finalAddress?.length > 0) {
      return finalAddress?.join(",");
    }
    return null;
  };
  const handlePagination = (_: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "offlineSuppliersPageNumber", index: page }));
  };

  useEffect(() => {
    createCancelToken();
    // setActiveTabData([]);
    getOfflineSuppliersListApi(searchValue, cancelTokenRef?.current?.token);
  }, [activeTabId, currentPage, selectedPage]);
  return (
    <>
     {toastStatus?.message &&  (
        <ToastNotification
          icon={toastStatus?.message? successToast : errorToast}
          content={toastStatus?.message}
          position={"top-right"}
          autoClose={5000}
        />
      )}
      <div className={styles.offlineSuppliersWrapper}>
        <div className={styles.heading}>
          <div className={styles.commonHeading}>
            {offlineSuppliers?.heading}
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.containerBody}>
            <div className={styles.tabs}>
              <div className={styles.tabsStyle}>
                <div className={styles.tabList}>
                  {offlineSuppliers?.tabsList.map((tab: any) => (
                    <div
                      className={styles.tabListItem}
                      onClick={() => selectTab(tab?.id)}
                    >
                      <div
                        className={`${styles.label} ${
                          tab?.id === activeTabId && styles.activeLabel
                        }`}
                      >
                        {tab.label}
                      </div>
                      {activeTabId === tab?.id && (
                        <div className={styles.border} />
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.hr} />
              </div>
              <div className={`${styles.actions}`}>
                <PageSearch
                  handleSearch={handleSearch}
                  customStyles={searchCustomStyles}
                  customPlaceHolder="Search by first name or last name or email id"
                  activeTab={activeTabId}
                />
                {/* temporarly disable sort in admin */}
                <div
                  className={`${styles.icon} ${styles.sort} ${styles.hide}`}
                  onClick={handleSort}
                  ref={sortContainerRef}
                >
                  <img src={sortIcon} alt="sort"></img>
                  {showSort && (
                    <div className={styles.sortList}>
                      <div className={styles.sortBy}>
                        {constants.ordersListing.SORT_BY}
                      </div>
                      {offlineSuppliers.sortOptions &&
                        offlineSuppliers.sortOptions.map(
                          (option: ISortOption, index: number) => (
                            <div
                              className={`${styles.sortOption} ${
                                index ===
                                  offlineSuppliers.sortOptions?.length - 1 &&
                                styles.lastOption
                              } ${
                                option?.id === prevSelected &&
                                styles.selectedOpt
                              }`}
                              onClick={() => handleSortingOptions(option?.id)}
                            >
                              {option.label}
                            </div>
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {activeTabData?.length > 0 && (
              <div className={styles.tableHeader}>
                <div className={`${styles.header} ${styles.w_20}`}>
                  {offlineSuppliers.listHeaders.name}
                </div>
                <div className={`${styles.header} ${styles.w_30}`}>
                  {offlineSuppliers.listHeaders.email}
                </div>
                <div className={`${styles.header} ${styles.w_20}`}>
                  {offlineSuppliers.listHeaders.businessName}
                </div>
                <div className={`${styles.header} ${styles.w_30}`}>
                  {offlineSuppliers.listHeaders.address}
                </div>
              </div>
            )}
            {activeTabData?.length > 0 && (
              <div className={styles.contentWrapper}>
                <div className={styles.tabsContent}>
                  {activeTabData?.length > 0 &&
                    activeTabData?.map((each: any) => (
                      <OfflineSupplierCard
                        customerId={each?.customerid}
                        email={each?.customermail}
                        customerName={each?.customername}
                        customerphone={each?.customerphone}
                        businessName={each?.business_setup?.[0]?.business_name}
                        address={getAddress(
                          each?.address?.[0]?.area,
                          each?.address?.[0]?.address,
                          each?.address?.[0]?.emirate?.name,
                          each?.address?.[0]?.country?.display_name
                        )}
                        business_setup_id={each?.business_setup?.[0]?.business_setup_id}
                        newAddress={Array.isArray(each?.address) && each?.address?.length > 0 ? each?.address[0] : null}
                      />
                    ))}
                </div>
              </div>
            )}
            {activeTabData?.length === 0 && (
              <div className={styles.emptyContainer}>
                <EmptyContainer title={"Woah!"} subText={"No users"} />
              </div>
            )}
          </div>
          <div className={styles.paginationContainer}>
            <PaginationComponent
              count={Math.ceil(totalCount / constants.paginationLimitPerPage)}
              page={currentPage}
              handlePagination={handlePagination}
              showFirstButton={true}
              showLastButton={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default OfflineSuppliersList;
