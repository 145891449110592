import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sortIcon from "../../../assets/images/ordersListing/sortIcon.svg";
import PageSearch from "../../../common/components/pageSearch/PageSearch";
import PaginationComponent from "../../../common/components/pagination/Pagination";
import PopupView from "../../../common/components/popupView/PopupView";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import {
  adminEndPoints,
  APIMethods,
} from "../../../common/constants/urlConstants";
import { AddSupplierPopup } from "../../../common/pages/productsListing/components/addSupplierPopup/AddSupplierPopup";
import {
  AdminUserOrderProps,
  IBuyerDataProps,
  ISortOption,
  IUserCardData,
  adminUserProps,
} from "../../../models/IAdminUserListingProps";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
// import { setSearchValue } from "../../../reducerSlices/headerSlice";
import { useAxios } from "../../../services/useAxios";
import {
  AdminUser,
  AdminUserListingCard,
} from "../adminUserListingCard/adminUserListingCard";
import styles from "./adminUserListing.module.scss";
import { EmptyContainer } from "../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { setTab } from "../../../reducerSlices/selectedTabSlice";
import { CancelToken } from "axios";
import { resetToastStatus } from "../../../reducerSlices/toastStatusSlice";
import successToast from "../../../assets/images/successToast.svg";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import debounce from "lodash/debounce";
import { setPage } from "../../../reducerSlices/selectedPageSlice";

const AdminUserListing = ({ buyerDataArr }: IBuyerDataProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedTab = useSelector(
    (state: any) => state?.selectedTab?.users_list ?? 0
  );
  const selectedPage = useSelector(
    (state: any) => state?.selectedPage?.usersPageNumber
  );
  // const itemsPerPage = constants.paginationLimitPerPage;
  const [, setSelectedCardData] = useState<IUserCardData | null>(null);
  // const [showSearch, setShowSearch] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [currentPage, setCurrentPage] = useState(selectedPage);
  const [prevSelected, setPrevSelected] = useState(-1);
  const [finalActive, setFinalActive] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [listDataCount, setListDataCount] = useState(0);
  const [activeTab, setActiveTab] = useState(selectedTab);
  const myRef = useRef<null | HTMLDivElement>(null);
  const { toastStatus } = useSelector((state: any) => state);
  const [createdToast, setCreatedToast] = useState(constants.defaultToastData);
  const [editedToast, setEditedToast] = useState(constants.defaultToastData);
  const dispatch = useDispatch();

  // const visibleData = (data: AdminUserOrderProps[]) => {
  //   const lastIndex = currentPage * itemsPerPage;
  //   const firstIndex = lastIndex - itemsPerPage;
  //   const visibleProducts = data.slice(firstIndex, lastIndex);
  //   return visibleProducts;
  // };

  const tabsData = [
    {
      id: 0,
      label: constants.productListing.ACTIVE,
    },
  ];
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    dispatch(setPage({ type: "usersPageNumber", index: page }));
  };
  const searchCustomStyles = {
    top: "0px",
  };

  useEffect(() => {
    myRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handleCardClick = (cardData: IUserCardData) => {
    setSelectedCardData(cardData);
    if (cardData) {
      if (activeTab === 2) {
        navigate(
          `${routeConfig.adminBuyerSetupForm}?id=${cardData?.business_setup_id}`,
          {
            state: { customerId: cardData?.id, previousPath: pathname },
          }
        );
      } else {
        navigate(
          `${routeConfig.adminSupplierSetupForm}/${
            cardData?.business_setup_id
          }/${true}`,
          {
            state: { customerId: cardData?.id, previousPath: pathname },
          }
        );
      }
    }
  };

  useEffect(() => {
    if (toastStatus?.message?.length > 0 && toastStatus?.status === "created") {
      setCreatedToast({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setCreatedToast(constants.defaultToastData);
      }, 2000);
    }
  }, [dispatch, toastStatus]);

  useEffect(() => {
    if (toastStatus?.message?.length > 0 && toastStatus?.status === "edited") {
      setEditedToast({
        message: toastStatus?.message,
        status: toastStatus?.status,
      });
      setTimeout(() => {
        dispatch(resetToastStatus());
        setEditedToast(constants.defaultToastData);
      }, 2000);
    }
  }, [dispatch, toastStatus]);

  const [showPopup, setShowPopup] = useState(false);
  const handlePopup = () => {
    setShowPopup(!showPopup);
  };

  // const handleSearch = () => {
  // setShowSearch(!showSearch);
  // };
  const handleSearch = (value: string, token?: CancelToken) => {
    setCurrentPage(1);
    setSearchValue(value);
    dispatch(setPage({ type: "usersPageNumber", index: 1 }));
    getUserData(value, token);
  };
  const handleSort = () => {
    setShowSort(!showSort);
  };
  const sortOptions = [
    {
      id: 0,
      label: constants.ordersListing.WHATS_NEW,
      onClick: () => {},
    },
    {
      id: 1,
      label: constants.productListing.SORT_A_Z,
      onClick: () => {},
    },
    {
      id: 2,
      label: constants.productListing.SORT_Z_A,
      onClick: () => {},
    },
  ];
  const handleSortingOptions = (id: number) => {
    setPrevSelected(id);
    sortOptions[id].onClick();
  };
  const sortContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sortContainerRef &&
        sortContainerRef.current &&
        !sortContainerRef?.current?.contains(event.target)
      ) {
        setShowSort(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // &take=${constants.paginationLimitPerPage}
  // &take=${constants.paginationLimitPerPage}

  const getBuyerList = useAxios({
    axiosParams: {
      url: `${adminEndPoints.getBuyerCustomerList}?${
        searchValue.length > 0 ? `q=${searchValue}&` : ""
      }&page=${currentPage}
      `,
    },
    method: APIMethods.get,
  });
  const getSupplierList = useAxios({
    axiosParams: {
      url: `${adminEndPoints.getSupplierCustomerList}?${
        searchValue.length > 0 ? `q=${searchValue}&` : ""
      }page=${currentPage}
      `,
    },
    method: APIMethods.get,
  });
  const getAdminList = useAxios({
    axiosParams: {
      url: `admin/user/list?limit=10&offset=${
        (currentPage - 1) * constants.paginationLimitPerPage
      }&q=${searchValue.length > 0 ? searchValue : ""}`,
    },
    method: APIMethods.get,
  });
  // const getUserData = async () => {
  //   dispatch(updateIsLoading(true));
  //   const buyerList: any = await getBuyerList?.fetchData();
  //   let supplierList: any;
  //   let data: any = [];
  //   if (buyerList) {
  //     supplierList = await getSupplierList?.fetchData();
  //     let buyersData = buyerList?.data?.buyers
  //       ? buyerList?.data?.buyers?.filter((ele: any) => ele?.business_name)
  //       : [];
  //     let suppliersData = supplierList?.data?.suppliers
  //       ? supplierList?.data?.suppliers?.filter(
  //           (ele: any) => ele?.business_name
  //         )
  //       : [];

  //     suppliersData.map((ele: any) => {
  //       if (ele) {
  //         ele["type"] = "supplier";
  //         ele["businessName"] = ele?.business_name;
  //         ele["emailID"] = ele?.business_email;
  //         ele["contactName"] = ele?.contact_name;
  //         ele["phNo"] = ele?.business_phone?.split("#")?.join("-");
  //         ele["id"] = ele?.business_id;
  //         ele["licenseNo"] = ele?.license_number;
  //         ele["licenseFile"] = {
  //           value: ele?.license_file,
  //           file: ele?.license_file,
  //         };
  //         ele["vatNo"] = ele?.vat_number;
  //         ele["vatFile"] = {
  //           value: ele?.vat_file,
  //           file: ele?.vat_file,
  //         };
  //         ele["countryName"] = ele?.country?.display_name;
  //         ele["country"] = {
  //           label: ele?.country?.display_name,
  //           value: ele?.country?.dial_codes,
  //           ...ele.country,
  //         };
  //         ele["userType"] = "Supplier";
  //       }
  //       return ele;
  //     });
  //     buyersData.map((ele: any) => {
  //       if (ele) {
  //         ele["type"] = "supplier";
  //         ele["businessName"] = ele?.business_name;
  //         ele["emailID"] = ele?.business_email;
  //         ele["contactName"] = ele?.contact_name;
  //         ele["phNo"] = ele?.business_phone?.split("#")?.join("-");
  //         ele["id"] = ele?.business_id;
  //         ele["licenseNo"] = ele?.license_number;
  //         ele["licenseFile"] = {
  //           value: ele?.license_file,
  //           file: ele?.license_file,
  //         };
  //         ele["vatNo"] = ele?.vat_number;
  //         ele["vatFile"] = {
  //           value: ele?.vat_file,
  //           file: ele?.vat_file,
  //         };
  //         ele["countryName"] = ele?.country?.display_name;
  //         ele["country"] = {
  //           label: ele?.country?.display_name,
  //           value: ele?.country?.dial_codes,
  //           ...ele?.country,
  //         };
  //         ele["userType"] = "Buyer";
  //       }
  //       return ele;
  //     });
  //     data = [...suppliersData, ...buyersData];
  //   }
  //   setShowEmptyContainer(data.length === 0);
  //   setFinalActive(data);
  //   setListDataCount(
  //     buyerList?.data?.count ?? 0 + supplierList?.data?.count ?? 0 ?? 0
  //   );
  //   dispatch(updateIsLoading(false));
  // };

  const getUserData = async (
    searchTerm?: string,
    cancelToken?: CancelToken
  ) => {
    let usersData: any = [];
    let response: any;
    dispatch(updateIsLoading(true));

    // to fetch admin users
    if (activeTab === 0) {
      if (searchTerm || searchTerm === "") {
        response = await getAdminList?.fetchData({
          axiosParams: {
            url: `admin/user/list?limit=10&offset=${1}&q=${
              searchTerm.length > 0 ? searchTerm : ""
            }`,
            cancelToken,
          },
          method: APIMethods.get,
        });
      } else {
        response = await getAdminList?.fetchData();
      }
      if (response && response?.status === "SUCCESS") {
        usersData = response?.data?.users ?? [];
        usersData?.map((ele: any) => {
          if (ele) {
            ele["adminId"] = ele?.id ?? "-";
            ele["email"] = ele?.email ?? "-";
            ele["firstName"] = ele?.first_name ?? "-";
            ele["lastName"] = ele?.last_name ?? "-";
            ele["phoneNumber"] =
              ele?.phone_number?.split("#")?.join("-").slice(0, 20) ?? "-";
            ele["address"] =
              `${ele?.saved_addresses[0]?.address}, ${ele?.saved_addresses[0]?.area}` ??
              "-";
            ele["businessName"] = ele?.business_name ?? "-";
          }
          return ele;
        });
        dispatch(updateIsLoading(false));
      }
      dispatch(updateIsLoading(false));
    }

    // to fetch supplier users
    // &take=${constants.paginationLimitPerPage}
    else if (activeTab === 1) {
      if (searchTerm || searchTerm === "") {
        response = await getSupplierList?.fetchData({
          axiosParams: {
            url: `${adminEndPoints.getSupplierCustomerList}?${
              searchTerm.length > 0 ? `q=${searchTerm}&` : ""
            }page=${1}
            `,
            cancelToken,
          },
          method: APIMethods.get,
        });
      } else {
        response = await getSupplierList?.fetchData();
      }
      if (response && response?.status === "SUCCESS") {
        usersData = response?.data?.customers;
        // ?.suppliers
        //   ? response?.data?.suppliers?.filter((ele: any) => ele?.business_name)
        //   : [];
        usersData.map((ele: any) => {
          if (ele) {
            ele["type"] = "supplier";
            ele["firstName"] = ele?.first_name;
            ele["lastName"] = ele?.last_name;
            ele["outletsCount"] = ele?.count;
            ele["businessName"] = ele?.business_name;
            // ele["businessName"] = ele?.business_name;
            // ele["emailID"] = ele?.email_id;
            // ele["contactName"] = ele?.business_stores?.[0]?.contact_name
            //   ? ele?.business_stores?.[0]?.contact_name
            //   : "-";
            // ele["phNo"] = ele?.business_stores?.[0]?.phone_number
            //   ? ele?.business_stores?.[0]?.phone_number?.split("#")?.join("-")
            //   : "-";
            // ele["id"] = ele?.id;
            // ele["licenseNo"] = ele?.license_number ?? "-";
            // ele["licenseFile"] = {
            //   value: ele?.license_file,
            //   file: ele?.license_file,
            // };
            // ele["vatNo"] = ele?.vat_number;
            // ele["vatFile"] = {
            //   value: ele?.vat_file,
            //   file: ele?.vat_file,
            // };
            // ele["countryName"] = ele?.country?.display_name;
            // ele["country"] = {
            //   label: ele?.address ? ele?.address?.country?.display_name : "-",
            //   value: ele?.address ? ele?.address?.country?.dial_codes : "-",
            //   ...ele.address?.country,
            // };
            // ele["userType"] = "Supplier";
            // ele["outletsCount"] = ele?.business_stores
            //   ? ele?.business_stores?.length
            //   : "-";
          }
          return ele;
        });
        // usersData.map((ele: any) => {
        //   if (ele) {
        //     ele["type"] = "supplier";
        //     ele["businessName"] = ele?.business_name;
        //     ele["emailID"] = ele?.business_email;
        //     ele["contactName"] = ele?.contact_name;
        //     ele["phNo"] = ele?.business_phone?.split("#")?.join("-");
        //     ele["id"] = ele?.business_id;
        //     ele["licenseNo"] = ele?.license_number;
        //     ele["licenseFile"] = {
        //       value: ele?.license_file,
        //       file: ele?.license_file,
        //     };
        //     ele["vatNo"] = ele?.vat_number;
        //     ele["vatFile"] = {
        //       value: ele?.vat_file,
        //       file: ele?.vat_file,
        //     };
        //     ele["countryName"] = ele?.country?.display_name;
        //     ele["country"] = {
        //       label: ele?.country?.display_name,
        //       value: ele?.country?.dial_codes,
        //       ...ele.country,
        //     };
        //     ele["userType"] = "Supplier";
        //   }
        //   return ele;
        // });
        dispatch(updateIsLoading(false));
      }
      dispatch(updateIsLoading(false));
    }
    // &take=${constants.paginationLimitPerPage}

    //  to fetch buyer users
    else if (activeTab === 2) {
      if (searchTerm || searchTerm === "") {
        response = await getBuyerList?.fetchData({
          axiosParams: {
            url: `${adminEndPoints.getBuyerCustomerList}?${
              searchTerm.length > 0 ? `q=${searchTerm}&` : ""
            }&page=${1}
            `,
            cancelToken,
          },
          method: APIMethods.get,
        });
      } else {
        response = await getBuyerList?.fetchData();
      }
      if (response && response?.status === "SUCCESS") {
        usersData = response?.data?.customers;
        // ?.buyers
        //   ? response?.data?.buyers?.filter((ele: any) => ele?.business_name)
        //   : [];
        usersData.map((ele: any) => {
          if (ele) {
            ele["type"] = "buyer";
            ele["firstName"] = ele?.first_name;
            ele["lastName"] = ele?.last_name;
            ele["outletsCount"] = ele?.count;
            ele["businessName"] = ele?.business_name;
            // ele["contactName"] = ele?.business_stores?.[0]?.contact_name
            //   ? ele?.business_stores?.[0]?.contact_name
            //   : "-";
            // ele["phNo"] = ele?.business_stores?.[0]?.phone_number
            //   ? ele?.business_stores?.[0]?.phone_number?.split("#")?.join("-")
            //   : "-";
            // ele["id"] = ele?.id;
            // ele["licenseNo"] = ele?.license_number ?? "-";
            // ele["licenseFile"] = {
            //   value: ele?.license_file,
            //   file: ele?.license_file,
            // };
            // ele["vatNo"] = ele?.vat_number;
            // ele["vatFile"] = {
            //   value: ele?.vat_file,
            //   file: ele?.vat_file,
            // };
            // ele["countryName"] = ele?.country?.display_name;
            // ele["country"] = {
            //   label: ele?.address ? ele?.address?.country?.display_name : "-",
            //   value: ele?.address ? ele?.address?.country?.dial_codes : "-",
            //   ...ele.address?.country,
            // };
            // ele["userType"] = "Buyer";
            // ele["outletsCount"] = ele?.business_stores
            //   ? ele?.business_stores?.length
            //   : "-";
          }
          return ele;
        });
        dispatch(updateIsLoading(false));
      }
      dispatch(updateIsLoading(false));
    }
    setShowEmptyContainer(usersData.length === 0);
    setFinalActive(usersData);
    setListDataCount(response?.data?.count ?? 0);
  };

  const debounceSearch = debounce(getUserData, 200);
  useEffect(() => {
    setFinalActive([]);
    debounceSearch();
  }, [activeTab, currentPage]);

  const [showEmptyContainer, setShowEmptyContainer] = useState(false);
  const TAB_LIST = [
    { key: "admin", value: "Admin" },
    { key: "supplier", value: "Supplier" },
    { key: "buyer", value: "Buyer" },
  ];
  const selectTab = (index: number) => {
    dispatch(setTab({ type: "users_list", index }));
    setActiveTab(index);
    // setSearchValue("");
    setCurrentPage(1);
    dispatch(setPage({ type: "usersPageNumber", index: 1 }));
  };
  return (
    <>
      {showPopup && (
        <PopupView
          heading={constants.productListing.ADD_NEW_SUPPLIER}
          childComponent={AddSupplierPopup}
          height={469}
          width={1116}
          handlePopup={handlePopup}
        />
      )}
      {tabsData.length > 0 && (
        <div className={styles.container}>
          <div className={styles.tabs}>
            <div className={styles.tabsStyle}>
              <div className={styles.tabList}>
                {TAB_LIST.map((tab, index) => (
                  <div
                    className={styles.tabListItem}
                    onClick={() => selectTab(index)}
                  >
                    <div
                      className={`${styles.label} ${
                        index === activeTab && styles.activeLabel
                      }`}
                    >
                      {tab.value}
                    </div>
                    {activeTab === index && <div className={styles.border} />}
                  </div>
                ))}
              </div>
              <div className={styles.hr} />
            </div>
            <div className={`${styles.actions}`}>
              <PageSearch
                handleSearch={handleSearch}
                customStyles={searchCustomStyles}
                customPlaceHolder={`Search by first name or last name or email id ${activeTab !==0 ? "or business name": ""}`}
                activeTab={activeTab}
              />
              {/* temporarly disable sort in admin */}
              <div
                className={`${styles.icon} ${styles.sort} ${styles.hide}`}
                onClick={handleSort}
                ref={sortContainerRef}
              >
                <img src={sortIcon} alt="sort"></img>
                {showSort && (
                  <div className={styles.sortList}>
                    <div className={styles.sortBy}>
                      {constants.ordersListing.SORT_BY}
                    </div>
                    {sortOptions &&
                      sortOptions.map((option: ISortOption, index: number) => (
                        <div
                          className={`${styles.sortOption} ${
                            index === sortOptions?.length - 1 &&
                            styles.lastOption
                          } ${
                            option?.id === prevSelected && styles.selectedOpt
                          }`}
                          onClick={() => handleSortingOptions(option?.id)}
                        >
                          {option.label}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className={styles.searchMain}>
            <div className={styles.hr}></div>
            <div className={`${styles.actions}`}>
              <PageSearch
                handleSearch={handleSearch}
                customStyles={searchCustomStyles}
              />
              <div
                className={`${styles.icon} ${styles.sort} ${styles.hide}`}
                onClick={handleSort}
                ref={sortContainerRef}
              >
                <img src={sortIcon} alt="sort"></img>
                {showSort && (
                  <div className={styles.sortList}>
                    <div className={styles.sortBy}>
                      {constants.ordersListing.SORT_BY}
                    </div>
                    {sortOptions &&
                      sortOptions.map((option: ISortOption, index: number) => (
                        <div
                          className={`${styles.sortOption} ${
                            index === sortOptions?.length - 1 &&
                            styles.lastOption
                          } ${
                            option?.id === prevSelected && styles.selectedOpt
                          }`}
                          onClick={() => handleSortingOptions(option?.id)}
                        >
                          {option.label}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div> */}
          {!showEmptyContainer ? (
            <div className={styles.tabsContent}>
              {activeTab === 0 ? (
                <div className={styles.tableHeader}>
                  <div className={`${styles.headerColumn1} ${styles.w_40} `}>
                    {constants.buyersListHeaders.email}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn2}`}
                  >
                    {constants.buyersListHeaders.firstName}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn3}`}
                  >
                    {constants.buyersListHeaders.lastName}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn4}`}
                  >
                    {constants.buyersListHeaders.phoneNO}
                  </div>
                  {/* <div
                    className={`${styles.headerColumn1} ${styles.headerColumn5}`}
                  >
                    {constants.buyersListHeaders.address}
                  </div> */}
                </div>
              ) : (
                <div className={styles.tableHeader}>
                  <div className={`${styles.headerColumn1} ${styles.w_40} ${styles.width}`}>
                    {constants.buyersListHeaders.email}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn2} `}
                  >
                    {constants.buyersListHeaders.businessName}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn2} `}
                  >
                    {constants.buyersListHeaders.firstName}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn3}`}
                  >
                    {constants.buyersListHeaders.lastName}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn4}`}
                  >
                    {activeTab === 2
                      ? constants.buyersListHeaders.outlets
                      : constants.buyersListHeaders.warehouses}
                  </div>
                  <div
                    className={`${styles.headerColumn1} ${styles.headerColumn5}`}
                  >
                    {constants.buyersListHeaders.userType}
                  </div>
                  {/* <div
                    className={`${styles.headerColumn1} ${styles.headerColumn5}`}
                  >
                    {constants.buyersListHeaders.address}
                  </div> */}
                  {/* <div
                    className={`${styles.headerColumn1} ${styles.headerColumn6}`}
                  >
                    {constants.buyersListHeaders.userType}
                  </div> */}
                </div>
              )}
              {!showEmptyContainer ? (
                <div className={styles.tabslistContainer}>
                  <div className={styles.ordersList} ref={myRef}>
                    {activeTab === 0
                      ? finalActive &&
                        finalActive?.map((user: adminUserProps) => (
                          <AdminUser adminUser={user} />
                        ))
                      : finalActive &&
                        finalActive?.map((order: AdminUserOrderProps) => (
                          <AdminUserListingCard
                            order={order}
                            handleCardClick={handleCardClick}
                          />
                        ))}
                  </div>
                  <div className={styles.paginationContainer}>
                    <PaginationComponent
                      count={Math.ceil(
                        listDataCount / constants.paginationLimitPerPage
                      )}
                      page={currentPage}
                      handlePagination={handlePagination}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.emptyContainer}>
                  <EmptyContainer />
                </div>
              )}
            </div>
          ) : (
            <div className={styles.emptyContainer}>
              <EmptyContainer title={"Woah!"} subText={"No users"} />
            </div>
          )}
        </div>
      )}
      {createdToast?.message != "" ? (
        <ToastNotification
          icon={successToast}
          content={createdToast?.message}
          position={"top-right"}
          autoClose={2000}
        />
      ) : (
        <></>
      )}
      {editedToast?.message != "" ? (
        <ToastNotification
          icon={successToast}
          content={editedToast?.message}
          position={"top-right"}
          autoClose={2000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminUserListing;
