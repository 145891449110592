import Modal from "@mui/material/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Star from "../../../assets/images/Star.svg";
import blankStar from "../../../assets/images/blankstar.svg";
import cancelAction from "../../../assets/images/cancelToast.svg";
import download from "../../../assets/images/download.svg";
import notesIcon from "../../../assets/images/notesIcon.svg";
// import star from "../../../assets/images/pinkStarIcon.svg";
import Breadcrumb from "../../../common/components/breadCrumb/BreadCrumb";
import Button from "../../../common/components/button/Button";
import BuyerDeliveryDetails from "../../../common/components/buyerDeliveryDetails/BuyerDeliveryDetails";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import PackageTracker from "../../../common/components/packageTracker/PackageTracker";
import { routeConfig } from "../../../common/constants/routeConfig";
import { APIMethods } from "../../../common/constants/urlConstants";
import {
  adminGetOrderDetailsById,
  resetAdminOrderDetails,
  updateAdminOrderStatus,
} from "../../../reducerSlices/adminManageOrderDetailsSlice";
import { useAxios } from "../../../services/useAxios";
import styles from "./AdminOrderDetailsStyles.module.scss";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import { constants } from "../../../common/constants/constants";
import successToast from "../../../assets/images/successToast.svg";
import ContactSupport from "../../../common/components/contactSupport/ContactSupport";
import errorToast from "../../../assets/images/errorToast.svg";
import ModifyOrders from "../../../components/login/modifyOrders/ModifyOrders";
import { setModifyOrdersModal } from "../../../reducerSlices/modifyOrdersSlice";
import { getQuestionFromOrderRating } from "../../../utils/functions";
const AdminOrderDetails = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { adminManageOrderDetails } = useSelector((state: any) => state);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [showContactSupport, setShowContactSupport] = useState(false);
  const adminGetOrderByID = useAxios({
    axiosParams: {
      url: `store/master-order/${orderId}`,
    },
    method: "GET",
  });

  const admingetOrderDetailsByid = async () => {
    if (orderId) {
      dispatch(updateIsLoading(true));
      const response: any = await adminGetOrderByID?.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        setBuyerNote(response?.data?.order?.notes);
        dispatch(adminGetOrderDetailsById(response?.data?.order));
      } else {
        dispatch(updateIsLoading(false));
      }
    }
  };
  useEffect(() => {
    admingetOrderDetailsByid();
  }, [orderId]);

  const orderDeliveryData = {
    status:
      adminManageOrderDetails &&
      (adminManageOrderDetails?.status != "shipped"
        ? adminManageOrderDetails?.status
        : "packed"),
    placedDate: adminManageOrderDetails && adminManageOrderDetails?.placedDate,
    confirmedDate:
      adminManageOrderDetails && adminManageOrderDetails?.confirmedDate,
    packedDate: adminManageOrderDetails && adminManageOrderDetails?.packedDate,
    onTheWayDate:
      adminManageOrderDetails && adminManageOrderDetails?.ontheWayDate,
    deliveryDate:
      adminManageOrderDetails && adminManageOrderDetails?.deliveredDate,
  };

  const orderStatusData = {
    orderId:
      adminManageOrderDetails &&
      adminManageOrderDetails?.orderPO &&
      adminManageOrderDetails?.orderPO,
    status: [
      {
        id: 0,
        message: "Placed",
        status: "inactive",
        statusDetails: orderDeliveryData.placedDate,
      },

      {
        id: 1,
        message: "Confirmed",
        status: "inactive",
        statusDetails: orderDeliveryData.confirmedDate,
      },
      {
        id: 2,
        message: "Packed",
        status: "inactive",
        statusDetails: orderDeliveryData.packedDate,
      },
      {
        id: 3,
        message: "On the Way",
        status: "inactive",
        statusDetails: orderDeliveryData.onTheWayDate,
      },
      {
        id: 4,
        message: "Delivered",
        status: "inactive", //cancelled, completed, inactive
        statusDetails: orderDeliveryData.deliveryDate,
      },
    ],
    orderType: adminManageOrderDetails?.orderType,
    productSummary: {
      estimatedSubtotal: adminManageOrderDetails?.subTotal || 0,
      deliverCharges:
        adminManageOrderDetails && adminManageOrderDetails?.deliveryCharge,
      vatCharges:
        adminManageOrderDetails && adminManageOrderDetails?.actualVat > 0
          ? adminManageOrderDetails?.actualVat
          : adminManageOrderDetails?.vatTax,
      vatPercentage:
        adminManageOrderDetails?.actualVat != 0 ||
        adminManageOrderDetails?.vatTax != 0
          ? "05"
          : "",
      totalAmount: adminManageOrderDetails?.total || 0,
      estimatedAmount: adminManageOrderDetails?.total || 0,
      recievedAmount: adminManageOrderDetails?.total || 0,
      totalItem:
        adminManageOrderDetails &&
        adminManageOrderDetails?.totalItems &&
        adminManageOrderDetails?.totalItems?.count,
      modifiedAmount: adminManageOrderDetails?.actualAmount || 0,
      modifiedSubtotal: adminManageOrderDetails?.actualSubtotal,
    },
  };
  const currentOrderStatus = orderDeliveryData?.status;
  const statusIdx = orderStatusData?.status?.findIndex(
    (each) => each?.message?.toLowerCase() === currentOrderStatus?.toLowerCase()
  );
  orderStatusData.status.forEach((each, index) => {
    if (currentOrderStatus === "placed") {
      if (index === statusIdx) {
        each.status = "completed";
      } else if (statusIdx + 1 === index) {
        each.status = "active";
      } else {
        each.status = "inactive";
      }
    } else if (currentOrderStatus === "delivered") {
      each.status = "completed";
    } else if (currentOrderStatus === "canceled") {
      each.status = "cancelled";
      if (each.message === "Delivered") {
        each.message = "Cancelled";
      }
    } else {
      if (currentOrderStatus === "confirmed") {
        if (each.message === "Packed") {
          each.message = "Packaging";
        }
      }
      if (index <= statusIdx) {
        each.status = "completed";
      } else if (index === statusIdx + 1) {
        each.status = "active";
      } else {
        each.status = "inactive";
      }
    }
  });
  const buyerDetails = [
    {
      id: 0,
      productKey: "Buyer name",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.buyerName,
    },
    {
      id: 1,
      productKey: "POC contact",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.buyerPhoneNum,
    },
    {
      id: 2,
      productKey: "Buyer POC",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.buyerPOC,
    },
    {
      id: 3,
      productKey: "Order value",
      productDescription: `AED ${
        adminManageOrderDetails &&
        Number(adminManageOrderDetails?.subTotal)?.toFixed(2)
      }`,
    },
    {
      id: 4,
      productKey: "Email",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.buyerEmail,
    },
    {
      id: 5,
      productKey: "Payment status",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.paidStatus,
    },
    {
      id: 6,
      productKey: "Placed On",
      productDescription:
        adminManageOrderDetails &&
        adminManageOrderDetails?.buyerDetailsPlacedDate,
    },
    {
      id: 7,
      productKey: "Mode of payment",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.paymentMode,
    },
    {
      id: 8,
      productKey: "Delivery Address (Outlet)",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.deliveryAddresss,
    },
  ];
  const supplierDetails = [
    {
      id: 9,
      productKey: "Name of manufacturer/ Packer/ Importer",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.soldBy,
    },
    {
      id: 10,
      productKey: "Sold by",
      productDescription:
        adminManageOrderDetails && adminManageOrderDetails?.manufactureName,
    },
    {
      id: 11,
      productKey: "Supplier's contact details",
      productDescription: `Contact number: ${
        adminManageOrderDetails?.supplierContactNo
          ? adminManageOrderDetails?.supplierContactNo
              ?.split("#")
              ?.join("-")
              ?.includes("+")
            ? adminManageOrderDetails?.supplierContactNo?.split("#")?.join("-")
            : `${
                adminManageOrderDetails?.supplierContactNo === "-" ? "" : "+"
              }${adminManageOrderDetails?.supplierContactNo
                ?.split("#")
                ?.join("-")}`
          : "-"
      }\n Email address: ${
        adminManageOrderDetails && adminManageOrderDetails?.supplierEmail
      }`,
    },
  ];
  const [isRateOrder, setIsRateOrder] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [buyerModalOpen, setbuyerModalOpen] = useState<boolean>(false);
  const handlebuyerModalClose = () => setbuyerModalOpen(false);
  const handleOpen = () => {
    getRatingDetails();
    setOpen(true);
  };
  const [buyerNote, setBuyerNote] = useState<string>("");
  const handleClose = () => {
    setIsRateOrder(false);
    setOpen(false);
  };
  const handlebuyerModalOpen = () => {
    // getRatingDetails();
    setbuyerModalOpen(true);
  };
  const dynamicLabel = adminManageOrderDetails?.orderPO ?? "Order details";
  const breadcrumbItems = [
    { label: "Manage orders", link: routeConfig.adminManageOrders },
    { label: dynamicLabel && dynamicLabel },
  ];
  const [dataTable, setDataTable] = useState([]);
  const downnloadInvoice = useAxios({
    axiosParams: {
      url: `store/invoice/download_invoice/${adminManageOrderDetails?.invoiceId}`,
      responseType: "arraybuffer",
    },
    method: APIMethods.get,
  });
  const downloadPDF = (data: ArrayBuffer, fileName: string) => {
    // Create a Blob from the binary data
    const blob = new Blob([data], { type: "application/pdf" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.pdf`;

    // Append the link to the document and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  const downnloadInvoiceAPI = async () => {
    const response: any = await downnloadInvoice?.fetchData();
    if (response) {
      downloadPDF(
        response,
        `${adminManageOrderDetails?.invoiceNo ?? "invoice"}`
      );
    }
  };
  useEffect(() => {
    setDataTable(adminManageOrderDetails?.orderedItemsList);
  }, [adminManageOrderDetails]);
  const handleRateOrder = () => {
    if (adminManageOrderDetails?.rating) {
      setIsRateOrder(false);
      handleOpen();
    } else {
      setIsRateOrder(true);
      setOpen(true);
    }
  };
  useEffect(() => {
    if (isRateOrder) {
      const orderListItemsFromPayload =
        adminManageOrderDetails?.totalItems?.items?.map((item: any) => {
          return {
            title: item?.title,
            variant_id: item?.variant_id,
          };
        });
      setOrderReview({
        orderRating: 1,
        productRating: 1,
        orderListItems: orderListItemsFromPayload,
        ratedItems: [],
        description: "",
      });
    } else {
      setOrderReview({
        orderRating: 1,
        productRating: 1,
        orderListItems: [],
        ratedItems: [],
        description: "",
      });
    }
  }, [isRateOrder]);
  const moreOptions = [
    {
      icon: notesIcon,
      label: "Buyer note",
      onClick: handlebuyerModalOpen,
    },
    // {
    //   icon: star,
    //   label: adminManageOrderDetails?.rating ? "View order rating" : "Rate order",
    //   onClick: handleRateOrder,
    // },
    {
      icon: download,
      label: "Download invoice",
      onClick: downnloadInvoiceAPI,
    },
  ];
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const moreOptionsRef = useRef<HTMLDivElement>(null);
  const [orderReview, setOrderReview] = useState<any>();
  // view rating api
  const getRating = useAxios({
    axiosParams: {
      url: `store/master-order/order-rating/${orderId}`,
    },
    method: APIMethods.get,
  });

  const showModifyModal = useSelector(
    (state: any) => state?.modifyOrdersModal?.showModal
  );

  const getRatingDetails = async () => {
    if (orderId) {
      const response: any = await getRating?.fetchData();
      // dispatch(updateIsLoading(true));
      if (response?.status === "SUCCESS") {
        setOrderReview(transformOrderRatingPayload(response?.data));
        // dispatch(updateIsLoading(false));
      }
    }
  };

  function removeDuplicates(arr: any[]): any[] {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }

  //function to generate payload for posting order rating
  const transformOrderRatingPayload = (
    payload: any
  ): {
    orderRating: number;
    productRating: number;
    orderListItems: { title: string; variant_id: string | number }[];
    ratedItems: { variant_id: string | number }[];
    description: string;
  } => {
    const orderListItemsFromPayload =
      adminManageOrderDetails?.totalItems?.items?.filter(
        (item: any, index: number) => {
          if (index < adminManageOrderDetails?.totalItems?.count) {
            return {
              title: item?.title,
              variant_id: item?.variant_id,
            };
          }
        }
      );
    return {
      orderRating: payload?.order_rating ?? 0,
      productRating: payload?.product_rating ?? 0,
      orderListItems: orderListItemsFromPayload,
      ratedItems: removeDuplicates(
        payload?.products?.map((each_product: any) => each_product?.variant_id)
      ),
      description: payload?.comments ?? "",
    };
  };

  // re-order api
  const reOrder = useAxios({
    axiosParams: {
      url: `store/master-order/re-order/${orderId}`,
      data: {
        buyer_id: adminManageOrderDetails && adminManageOrderDetails?.buyerId,
        // cart_id: adminManageOrderDetails && adminManageOrderDetails?.cartId, // removed according to the new reorder api index 20
        outlet_id: adminManageOrderDetails && adminManageOrderDetails?.outletId,
      },
    },
    method: APIMethods.post,
  });

  const reOrderItem = async () => {
    if (orderId) {
      dispatch(updateIsLoading(true));
      const response: any = await reOrder?.fetchData();
      dispatch(updateIsLoading(false));
      if (response?.status === "SUCCESS") {
        setToastData({
          message:
            "All the items from this order were added to the buyer's cart successfully.",
          status: "success",
        });
      }
    }
    setTimeout(() => {
      setToastData(constants.defaultToastData);
    }, 2000);
  };

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOrderReview({
      ...orderReview,
      description: event?.target?.value,
    });
  };

  const [userType] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );
  console.log(userType?.typeOfUser, "userType");

  // rate order api
  const rateOrderAPI = useAxios({
    axiosParams: {
      url: `store/master-order/${orderId}/rate-order`,
      data: {
        order_rating: orderReview?.orderRating,
        comments: orderReview?.description,
        customer_id:
          userType?.typeOfUser[0] === "admin"
            ? userType?.id
            : adminManageOrderDetails?.buyerId,
        products: orderReview?.ratedItems?.map((each: any) => ({
          variant_id: each,
        })),
        products_rating: orderReview?.productRating,
      },
    },
    method: APIMethods.post,
  });

  const submitOrderReview = async () => {
    if (orderId && isRateOrder) {
      dispatch(updateIsLoading(true));
      const response: any = await rateOrderAPI?.fetchData();
      if (response?.status === "SUCCESS") {
        dispatch(
          updateAdminOrderStatus({ rating: orderReview?.orderRating } as any)
        );
        dispatch(updateIsLoading(false));
        setToastData({
          message: response?.message ?? "Thanks for rating the order",
          status: "success",
        });
      } else {
        setToastData({
          message: "Unable to rate your order",
          status: "failure",
        });
        dispatch(updateIsLoading(false));
      }
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 2000);
      handleClose();
    }
  };

  const handleOrderRatingSubmit = () => {
    submitOrderReview();
  };

  const handleModifyOrderModalClose = () => {
    dispatch(setModifyOrdersModal(false));
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        moreOptionsRef &&
        moreOptionsRef.current &&
        !moreOptionsRef?.current?.contains(event.target)
      ) {
        setShowMoreOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(resetAdminOrderDetails());
    };
  }, []);
  const downnloadOrderApi = useAxios({
    axiosParams: {
      url: `store/master-order/download-po/${orderId}`,
      responseType: "arraybuffer",
    },
    method: APIMethods.get,
  });
  const downloadOrder = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await downnloadOrderApi?.fetchData();
    dispatch(updateIsLoading(false));

    if (response) {
      downloadPDF(response, `${dynamicLabel?.replace(" ", "_")}`);
    }
  };
  return (
    <>
      <div className={styles.parent}>
        <div className={styles.outerContainer}>
          {/* <div className={styles.searchContainer}>
            <Search
              searchSupplier={true}
              placeholder="Search for your supply soulmate!"
            />
          </div> */}
          <div className={styles.buttonOuterContainer}>
            <Breadcrumb items={breadcrumbItems} />
            <div className={styles.buttonInnerContainer}>
              <Button label="Download order" link handleClick={downloadOrder} />
              {currentOrderStatus === "on the way" && (
                <>
                  <div>
                    <Button
                      label="Mark as received"
                      link
                      handleClick={() => {}}
                    />
                  </div>
                  <div>
                    <Button
                      label="Download invoice"
                      link
                      handleClick={() => {}}
                    />
                  </div>
                </>
              )}
              {(currentOrderStatus === "delivered" ||
                currentOrderStatus === "canceled") &&
                adminManageOrderDetails?.orderType === "Online" && (
                  <div>
                    <Button
                      label="Reorder"
                      link
                      handleClick={() => {
                        reOrderItem();
                      }}
                    />
                  </div>
                )}
              {currentOrderStatus === "delivered" &&
              adminManageOrderDetails?.orderType === "Online" ? (
                <>
                  <div>
                    <Button
                      label={`${
                        adminManageOrderDetails?.rating
                          ? "View order rating"
                          : "Rate order"
                      }`}
                      link
                      handleClick={handleRateOrder}
                    />
                  </div>
                </>
              ) : null}
              {buyerNote?.length > 0 ? (
                <div>
                  <Button
                    label="View buyer note"
                    link
                    handleClick={handlebuyerModalOpen}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className={styles.contactSupport}>
                <Button
                  label="Contact support"
                  link
                  handleClick={(event: any) => {
                    event.stopPropagation();
                    setShowContactSupport(!showContactSupport);
                  }}
                />
                {showContactSupport && (
                  <ContactSupport
                    setCloseContactSupport={setShowContactSupport}
                  ></ContactSupport>
                )}
              </div>
              {(currentOrderStatus === "delivered" ||
                currentOrderStatus === "cancelled") && (
                <div className={styles.actions} ref={moreOptionsRef}>
                  <div>
                    <Button
                      label="More actions"
                      link
                      handleClick={() => {
                        setShowMoreOptions(true);
                      }}
                    />
                  </div>
                  {showMoreOptions && (
                    <div className={styles.actionItems}>
                      {moreOptions?.map((item) => {
                        if (item?.label != "Buyer note") {
                          return (
                            <div
                              className={styles.option}
                              onClick={item?.onClick}
                            >
                              <img src={item.icon} alt={item.label} />
                              <div className={styles.label}>{item.label}</div>
                            </div>
                          );
                        } else {
                          if (adminManageOrderDetails?.notes) {
                            return (
                              <div
                                className={styles.option}
                                onClick={item?.onClick}
                              >
                                <img src={item.icon} alt={item.label} />
                                <div className={styles.label}>{item.label}</div>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.mainContainer}>
            {/* order tracker */}
            <PackageTracker orderStatus={{ ...orderStatusData }} />

            {/* buyer detals */}
            <BuyerDeliveryDetails
              // orderStatus={orderStatusData}
              buyerDetails={buyerDetails.concat(supplierDetails)}
              isAdmin={true}
              productSummary={orderStatusData.productSummary}
              currentOrderStatus={currentOrderStatus?.toLowerCase()}
              orderId={orderId}
              orderStatus={adminManageOrderDetails?.orderStatus}
              orderStatusDetails={orderDeliveryData}
              paymentStatus={
                adminManageOrderDetails && adminManageOrderDetails?.paidStatus
              }
              orderType={adminManageOrderDetails?.orderType}
            />
            {/* table */}
            <div className={styles.container}>
              <h5>Items Quantity: {dataTable?.length} items</h5>
              <div className={styles.tableContainer}>
                <table>
                  <thead>
                    <th>Watermelon product code</th>
                    <th>Product name</th>
                    <th>Product brand</th>
                    <th>Order items</th>
                    <th>Line item amount</th>
                    {currentOrderStatus === "delivered" ? (
                      <th>Updated amount</th>
                    ) : null}
                  </thead>
                  <tbody>
                    {dataTable &&
                      dataTable?.length > 0 &&
                      dataTable?.map((item: any) => (
                        <tr key={item?.id}>
                          <td>{item?.productCode}</td>
                          <td>{item?.productName}</td>
                          <td>{item?.productBrand}</td>
                          <td>{item?.orderItems}</td>
                          <td>{item?.itemAmount}</td>
                          {currentOrderStatus === "delivered" ? (
                            <td>{item?.updatedAmount}</td>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose} className={styles.ratingModal}>
        <div className={styles.modalFullContainer}>
          <div className={styles.modalBody}>
            <div className={styles.heading}>
              <div className={styles.leftContainer}>Order Review</div>
              <div className={styles.rightContainer}>
                <img src={cancelAction} alt="cancel" onClick={handleClose} />
              </div>
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.body}>
              <div
                className={`${styles.ratingContainer} ${
                  !isRateOrder ? styles.viewMode : null
                }`}
              >
                <div className={styles.ratingBox}>
                  <div className={styles.upContainer}>
                    <div className={styles.ratingValue}>
                      <span>{orderReview?.orderRating}</span>
                      <span>&nbsp;/&nbsp;</span>
                      <span>5</span>
                    </div>
                    <div className={styles.ratingStarContainer}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setOrderReview({
                              ...orderReview,
                              orderRating: index + 1,
                            });
                          }}
                          className={`${isRateOrder ? styles.clickable : null}`}
                        >
                          <img
                            src={
                              index < orderReview?.orderRating
                                ? Star
                                : blankStar
                            }
                            alt="star"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.ratingBlock}>Product quality</div>
                </div>
                <div className={styles.ratingBox}>
                  <div className={styles.upContainer}>
                    <div className={styles.ratingValue}>
                      <span>{orderReview?.productRating}</span>
                      <span>&nbsp;/&nbsp;</span>
                      <span>5</span>
                    </div>
                    <div className={styles.ratingStarContainer}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          onClick={() => {
                            setOrderReview({
                              ...orderReview,
                              productRating: index + 1,
                            });
                          }}
                          className={`${isRateOrder ? styles.clickable : null}`}
                        >
                          <img
                            key={index}
                            src={
                              index < orderReview?.productRating
                                ? Star
                                : blankStar
                            }
                            alt="star"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.ratingBlock}>Overall rating</div>
                </div>
              </div>
              <div className={styles.questionContainer}>
                <div className={styles.heading}>
                  {/* Which items do you regret ordering ? */}
                  {getQuestionFromOrderRating(
                    orderReview?.orderRating,
                    orderReview?.productRating
                  )}
                </div>
                {isRateOrder && (
                  <div className={styles.selectAll}>
                    <CheckboxButton
                      checked={
                        orderReview?.ratedItems?.length ===
                        orderReview?.orderListItems?.length
                      }
                      handleChange={() => {
                        const isChecked =
                          orderReview?.ratedItems?.length ===
                          orderReview?.orderListItems?.length;
                        if (isChecked) {
                          setOrderReview({
                            ...orderReview,
                            ratedItems: [],
                          });
                        }
                        if (!isChecked) {
                          setOrderReview({
                            ...orderReview,
                            ratedItems: orderReview?.orderListItems?.map(
                              (item: any) => item?.variant_id
                            ),
                          });
                        }
                      }}
                    />
                    <label className={styles.label}>Select all</label>
                  </div>
                )}
                <div className={`${styles.options}`}>
                  {orderReview?.orderListItems.map((item: any) => {
                    const isChecked = orderReview?.ratedItems?.includes(
                      item?.variant_id
                    );
                    return (
                      <div
                        className={`${styles.option} ${
                          !isRateOrder ? styles.viewMode : null
                        }`}
                        key={item?.variant_id}
                      >
                        <CheckboxButton
                          checked={isChecked}
                          handleChange={() => {
                            const isChecked = orderReview?.ratedItems?.includes(
                              item?.variant_id
                            );
                            if (isChecked) {
                              const selectedItems =
                                orderReview?.ratedItems?.filter(
                                  (variant_id: any) =>
                                    variant_id != item?.variant_id
                                );
                              setOrderReview({
                                ...orderReview,
                                ratedItems: selectedItems,
                              });
                            } else {
                              setOrderReview({
                                ...orderReview,
                                ratedItems: [
                                  ...orderReview?.ratedItems,
                                  item?.variant_id,
                                ],
                              });
                            }
                          }}
                        />
                        <label className={styles.label}>{item?.title}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={`${styles.textAreaContainer}`}>
                {(orderReview?.description || isRateOrder) && (
                  <textarea
                    placeholder="Share your thoughts and impress us with your epic review skills!"
                    readOnly={!isRateOrder}
                    onChange={handleTextAreaChange}
                  >
                    {isRateOrder ? "" : orderReview?.description}
                  </textarea>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              secondary={isRateOrder}
              label="Cancel"
              handleClick={handleClose}
              width="100px"
              customClass={styles.button}
            />
            {isRateOrder && (
              <Button
                label="Submit"
                handleClick={handleOrderRatingSubmit}
                width="100px"
                customClass={styles.button}
              />
            )}
          </div>
        </div>
      </Modal>
      {/* buyer note modal  starts*/}
      {/* Modilfy orders modal */}
      <Modal
        open={showModifyModal}
        onClose={handleModifyOrderModalClose}
        className={styles.modifyOrder}
      >
        <ModifyOrders
          customerId={adminManageOrderDetails?.buyerId}
          orderId={orderId}
          dataTable={adminManageOrderDetails?.initialModifyOrdersData}
          currentOrderStatus={currentOrderStatus}
          minimumOrderValue={adminManageOrderDetails?.miniMumOrderValue}
        />
      </Modal>
      <Modal
        open={buyerModalOpen}
        onClose={handlebuyerModalClose}
        className={styles.ratingModal}
      >
        <div className={styles.modalFullContainer}>
          <div className={styles.modalBody}>
            <div className={styles.heading}>
              <div className={styles.leftContainer}>Buyer Note</div>
              <div className={styles.rightContainer}>
                <img
                  src={cancelAction}
                  alt=""
                  onClick={handlebuyerModalClose}
                />
              </div>
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.body}>
              <div className={styles.buyerNote}>{buyerNote ?? "-"}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              label="Cancel"
              handleClick={handlebuyerModalClose}
              width="100px"
              customClass={styles.button}
            />
          </div>
        </div>
      </Modal>
      {/* buyer note modal ends*/}

      {toastData?.message ? (
        <ToastNotification
          icon={toastData?.status === "success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminOrderDetails;
