import React, { useEffect, useState } from "react";
import styles from "./ModifyOrders.module.scss";
import Button from "../../../common/components/button/Button";
import InputField from "../../../common/components/formFields/inputField/inputField";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setModifyOrdersModal } from "../../../reducerSlices/modifyOrdersSlice";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import cancelAction from "../../../assets/images/cancelToast.svg";
import { useAxios } from "../../../services/useAxios";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { adminGetOrderDetailsById } from "../../../reducerSlices/adminManageOrderDetailsSlice";
import { getOrderListDetailsById } from "../../../reducerSlices/manageOrderDetails";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
const ModifyOrders = ({
  customerId,
  orderId,
  dataTable,
  currentOrderStatus,
  minimumOrderValue,
}: any) => {
  const [modifyOrderData, setModifyOrderData] = React.useState<any>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  // const [initialData, setInitialData] = useState<any>();
  const [deletedItems, setDeletedItems] = useState<any>([]);
  useEffect(() => {
    const userData = localStorage.getItem("loggedInUser");
    if (userData != null) {
      const loggedInUser = JSON.parse(userData);
      if (loggedInUser?.typeOfUser.includes("admin")) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, []);

  const dispatch = useDispatch();
  const tableDataShema = yup.array().of(
    yup.object().shape({
      id: yup.string(),
      productBrand: yup.string(),
      productCode: yup.string(),
      productName: yup.string(),
      updatedAmount: yup.string(),
      itemAmountUnit: yup.string(),
      orderItemsUnit: yup.string(),
      offer: yup.number(),
      itemAmount: yup
      .number()
      .typeError("Enter item amount")
      .required("Enter item amount")
      .test("conditional-min", function (value) {
        const { offer } = this.parent;
        if (offer === 100) {
          return value !== undefined && value >= 0.00
            ? true
            : this.createError({ message: "Min amount should be 0" });
        } else {
          return value !== undefined && value >= 0.01
            ? true
            : this.createError({ message: "Min amount should be 1" });
        }
      }),
      orderItems: yup
        .number()
        .min(0.01, "Min Qty. should be 1")
        .required("Enter order item")
        .typeError("Enter order items")
        // .test("is-decimal", "Order items should not be decimal", (value) =>
        //   Number.isInteger(value)
        // ),
        .test("is-decimal", "Qty. should have max 2 decimals", (value) => {
          if (value === undefined || value === null) {
            return true; // Allow empty values if needed
          }
          return /^\d+(\.\d{1,2})?$/.test(value.toString());
        }),
    })
  );

  const overallSchema = yup.object({
    tableData: tableDataShema,
  });

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
  }: // getValues,
  any = useForm({
    resolver: yupResolver(overallSchema),
    defaultValues: {
      tableData: [],
    },
  });

  const handleModifyOrderModalClose = () => {
    dispatch(setModifyOrdersModal(false));
  };

  const { fields, remove } = useFieldArray({
    control,
    name: "tableData",
  });
  const [hasError, setHasError] = useState(false);
  const onChangeField = React.useCallback(
    (name: any, index: number) => (text: any) => {
      setValue(name, text);
      setModifyOrderData((prevData: any) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          line_item_id: newData[index]?.line_item_id,
          [name.endsWith("orderItems") ? "quantity" : "unit_price"]:
            text && +text,
        };
        return newData;
      });
      trigger(name);
      setHasError(false);
      // if (name.includes("orderItems")) {
      //   setHasError(!checkIfMovMeets());
      // const calculatedAmount = handleAmountCalculation(
      //   +text,
      //   +values?.[index]?.itemAmount?.split(" ")[1],
      //   +values?.[index]?.offer
      // );
      // setValue(`tableData[${index}].itemAmount`, calculatedAmount);
      // }
    },
    [setValue, setModifyOrderData]
  );

  // const checkIfMovMeets = () => {
  //   let totalAmount: number = 0;
  //   const data: any[] = getValues()?.tableData;
  //   data.map(
  //     (each: any) =>
  //       (totalAmount += Number(
  //         each?.itemAmount * (each?.orderItems > 0 ? each?.orderItems : 0)
  //       ))
  //   );
  //   if (totalAmount > minimumOrderValue) return true;
  //   return false;
  // };
  useEffect(() => {
    if (dataTable && dataTable.length > 0) {
      const orderData = dataTable.map((item: any, index: any) => {
        return {
          id: item?.id,
          lineItemId: item?.id,
          itemAmount: item?.isSpecialPriceApplied
            ? Number(+item?.itemAmount?.split(" ")[1]).toFixed(2)
            : Number(
                +item?.itemAmount?.split(" ")[1] -
                  +item?.itemAmount?.split(" ")[1] * (item?.offer / 100)
              ).toFixed(2),
          itemAmountUnit: item?.itemAmount?.split(" ")[0],
          orderItems: Number(item?.orderItems?.split(" ")[0]),
          orderItemsUnit: item?.orderItems?.split(" ")[1],
          productBrand: item?.productBrand,
          productCode: item?.productCode,
          productName: item?.productName,
          updatedAmount: item?.updatedAmount,
          offer: item.offer
        };
      });
      // setInitialData(orderData);
      setValue(`tableData`, orderData);
    }
  }, [dataTable]);

  const getOrderByID = useAxios({
    axiosParams: {
      url: `store/master-order/${orderId}`,
    },
    method: "GET",
  });
  const getOrderDetailsByid = async () => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await getOrderByID?.fetchData();
      if (response && response.status === "SUCCESS") {
        if (isAdmin) {
          dispatch(adminGetOrderDetailsById(response?.data?.order));
        } else {
          dispatch(getOrderListDetailsById(response?.data?.order));
        }
        dispatch(updateIsLoading(false));
      }
    } catch (error) {}
  };

  useEffect(() => {
    const modifyOrderData = dataTable?.map((item: any, index: any) => {
      return {
        line_item_id: item?.id,
        quantity: item?.orderItems && +item?.orderItems?.split(" ")[0],
        unit_price: item?.itemAmount && +item?.itemAmount?.split(" ")[1],
      };
    });
    setModifyOrderData(modifyOrderData);
  }, [dataTable]);
  // const getDeletedItems = () => {
  //   return modifyOrderData
  //     ?.filter((item: any) => item?.quantity === 0)
  //     ?.map((deletedItem: any) => deletedItem.line_item_id);
  // };
  const modifyOrders = useAxios({
    axiosParams: {
      url: `store/master-order/modify-order/${orderId}`,
      data: {
        buyer_id: customerId,
        status: "modified",
        line_items: modifyOrderData,
        delete_items: deletedItems,
      },
    },

    method: "POST",
  });

  // const handleAmountCalculation = (
  //   amount: number,
  //   quantity: number,
  //   offer: number
  // ) => {
  //   const totalAmount = amount * quantity - amount * quantity * (offer / 100);
  //   return totalAmount.toFixed(2);
  // };

  const handleModifyOrderSubmit = async (data: any) => {
    try {
      const response: any = await modifyOrders?.fetchData();
      if (response && response.status === "SUCCESS") {
        getOrderDetailsByid();
        dispatch(setModifyOrdersModal(false));
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  const deleteItem = (id: string, index: number) => {
    remove(index);
    setDeletedItems((prev: any) => [...prev, id]);
  };
  return (
    <>
      <div className={styles.modalFullContainer}>
        <div className={styles.modalBody}>
          <div className={styles.heading}>
            <div className={styles.leftContainer}>Modify Order</div>
            <div className={styles.rightContainer}>
              <img
                src={cancelAction}
                alt=""
                onClick={handleModifyOrderModalClose}
              />
            </div>
          </div>
          <div className={styles.seperator}></div>
          <div className={styles.body}>
            <div className={styles.productListContainer}>
              <form className={styles.tableContainer}>
                <table>
                  <thead>
                    <th>Watermelon Product code</th>
                    <th>Product name</th>
                    <th>Product brand</th>
                    <th>Order items</th>
                    <th>Item amount</th>
                    {currentOrderStatus === "delivered" ? (
                      <th>Updated Amount</th>
                    ) : null}
                  </thead>
                  <tbody>
                    {fields &&
                      fields?.length > 0 &&
                      fields?.map((item: any, index) => (
                        <tr key={item?.id} className={styles.tableRow}>
                          <td>{item?.productCode}</td>
                          <td>{item?.productName}</td>
                          <td
                            className={styles.textOverflow}
                            title={item?.productBrand}
                          >
                            {item?.productBrand}
                          </td>
                          <td>
                            <div className={styles.inputField}>
                              <div>
                                <InputField
                                  id={`tableData.${index}.orderItems`}
                                  register={register(
                                    `tableData[${index}].orderItems`
                                  )}
                                  onChange={onChangeField(
                                    `tableData[${index}].orderItems`,
                                    index
                                  )}
                                  type="number"
                                  width="130px"
                                  errorMessage={
                                    errors?.tableData &&
                                    errors?.tableData.length
                                      ? errors?.tableData[index]?.orderItems
                                          ?.message
                                      : ""
                                  }
                                />
                              </div>
                              <div>{item?.orderItemsUnit}</div>
                            </div>
                          </td>
                          <td>
                            <div className={styles.inputField}>
                              <div>
                                <InputField
                                  // value={initialData[index]?.itemAmount}
                                  id={`tableData.${index}.itemAmount`}
                                  register={register(
                                    `tableData[${index}].itemAmount`
                                  )}
                                  onChange={onChangeField(
                                    `tableData[${index}].itemAmount`,
                                    index
                                  )}
                                  // disab
                                  width="130px"
                                  type="number"
                                  editForm={!isAdmin}
                                  errorMessage={
                                    errors?.tableData &&
                                    errors?.tableData.length
                                      ? errors?.tableData[index]?.itemAmount
                                          ?.message
                                      : ""
                                  }
                                />
                              </div>
                              <div>{item?.itemAmountUnit}</div>
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${styles.delete} ${
                                fields?.length <= 1 && styles.disble
                              }`}
                              onClick={
                                fields?.length <= 1
                                  ? () => {
                                      setHasError(true);
                                    }
                                  : () => deleteItem(item?.lineItemId, index)
                              }
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                            </div>
                          </td>
                          {currentOrderStatus === "delivered" ? (
                            <td>{item?.updatedAmount}</td>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          {hasError && (
            <div
              className={styles.errorMessage}
            >{`Please reject the order instead of deleting`}</div>
          )}
          <Button
            label="Cancel"
            handleClick={() => {
              handleModifyOrderModalClose();
            }}
            width="100px"
            customClass={styles.button}
            secondary={true}
          />
          <Button
            label="Submit"
            handleClick={handleSubmit((data: any) =>
              handleModifyOrderSubmit(data)
            )}
            width="100px"
            customClass={styles.button}
          />
        </div>
      </div>
    </>
  );
};

export default ModifyOrders;
