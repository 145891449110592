import styles from "./OfflineSupplierCardStyles.module.scss";
import { useNavigate } from "react-router-dom";
const OfflineSupplierCard = ({
  customerId,
  email,
  customerName,
  businessName,
  address,
  newAddress,
  business_setup_id,
  customerphone
}: any) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/admin/offline-suppliers/${customerId}`);
  };
  return (
    <div className={styles.card} key={customerId} onClick={handleCardClick}>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_20} ${styles.name}`}
        title={customerName}
      >
        {customerName ?? "-"}
      </div>
      <div
        className={`${styles.columnValue}  ${styles.textOverflow} ${styles.w_30}`}
        title={email}
      >
        {email ?? "-"}
      </div>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_20}`}
        title={businessName}
      >
        {businessName ?? "-"}
      </div>
      <div
        className={`${styles.columnValue} ${styles.textOverflow} ${styles.w_30}`}
        title={address}
      >
        {address ?? "-"}
      </div>
    </div>
  );
};

export default OfflineSupplierCard;
