import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Star from "../../../assets/images/Star.svg";
import blankStar from "../../../assets/images/blankstar.svg";
import cancelAction from "../../../assets/images/cancelToast.svg";
import Breadcrumb from "../../../common/components/breadCrumb/BreadCrumb";
import Button from "../../../common/components/button/Button";
import BuyerDeliveryDetails from "../../../common/components/buyerDeliveryDetails/BuyerDeliveryDetails";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import PackageTracker from "../../../common/components/packageTracker/PackageTracker";
import { routeConfig } from "../../../common/constants/routeConfig";
import { getOrderListDetailsById } from "../../../reducerSlices/manageOrderDetails";
import { useAxios } from "../../../services/useAxios";
import styles from "./ManageOrders.module.scss";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import ContactSupport from "../../../common/components/contactSupport/ContactSupport";
import { setModifyOrdersModal } from "../../../reducerSlices/modifyOrdersSlice";
import ModifyOrders from "../../../components/login/modifyOrders/ModifyOrders";
import { getQuestionFromOrderRating } from "../../../utils/functions";
import { APIMethods } from "../../../common/constants/urlConstants";

const ManageOrders = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const manageOrderDetails = useSelector(
    (state: any) => state.manageOrderDetails
  );
  const [orderReview, setOrderReview] = useState<any>();
  const [buyerNote, setBuyerNote] = useState<string>("");
  const [showContactSupport, setShowContactSupport] = useState(false);

  //api to fetch order details
  const getOrderByiD = useAxios({
    axiosParams: {
      url: `store/master-order/${orderId}`,
    },
    method: "GET",
  });

  const showModifyModal = useSelector(
    (state: any) => state?.modifyOrdersModal?.showModal
  );

  const getOrderDetailsByid = async () => {
    if (orderId) {
      dispatch(updateIsLoading(true));
      const response: any = await getOrderByiD?.fetchData();
      if (response?.status === "SUCCESS") {
        setBuyerNote(response?.data?.order?.notes);
        dispatch(getOrderListDetailsById(response?.data?.order));
        dispatch(updateIsLoading(false));
      }
    }
  };
  useEffect(() => {
    getOrderDetailsByid();
  }, [orderId]);

  //api to fetch order rating data
  const getRating = useAxios({
    axiosParams: {
      url: `store/master-order/order-rating/${orderId}`,
    },
  });

  const getRatingDetails = async () => {
    if (orderId) {
      // dispatch(updateIsLoading(true));
      const response: any = await getRating.fetchData();
      if (response?.status === "SUCCESS") {
        setOrderReview(transformOrderRatingPayload(response?.data));
        // setBuyerNote(response?.data?.order_notes);

        // dispatch(updateIsLoading(false));
      }
    }
  };
  function removeDuplicates(arr: any[]): any[] {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }

  //this function is used to generate the payload to post the rating
  const transformOrderRatingPayload = (
    payload: any
  ): {
    orderRating: number;
    productRating: number;
    orderListItems: { title: string; variant_id: string | number }[];
    ratedItems: { variant_id: string | number }[];
    description: string;
  } => {
    const orderListItemsFromPayload =
      manageOrderDetails?.totalItems?.items?.map((item: any) => {
        return {
          title: item?.title,
          variant_id: item?.variant_id,
        };
      });
    return {
      orderRating: payload?.order_rating ?? 0,
      productRating: payload?.product_rating ?? 0,
      orderListItems: orderListItemsFromPayload,
      ratedItems: removeDuplicates(
        payload?.products?.map((each_product: any) => each_product?.variant_id)
      ),
      description: payload?.comments ?? "",
    };
  };

  const orderDeliveryData = {
    status:
      manageOrderDetails &&
      (manageOrderDetails?.status !== "shipped"
        ? manageOrderDetails?.status
        : "packed"),
    placedDate: manageOrderDetails && manageOrderDetails?.placedDate,
    confirmedDate: manageOrderDetails && manageOrderDetails?.confirmedDate,
    packedDate: manageOrderDetails && manageOrderDetails?.packedDate,
    onTheWayDate: manageOrderDetails && manageOrderDetails?.ontheWayDate,
    deliveryDate: manageOrderDetails && manageOrderDetails?.deliveredDate,
  };

  const orderStatus = {
    orderId:
      manageOrderDetails &&
      manageOrderDetails?.orderPO &&
      manageOrderDetails?.orderPO,
    status: [
      {
        message: "Placed",
        status: "inactive",
        statusDetails: orderDeliveryData.placedDate,
      },

      {
        message: "Confirmed",
        status: "inactive",
        statusDetails: orderDeliveryData.confirmedDate,
      },
      {
        message: "Packed",
        status: "inactive",
        statusDetails: orderDeliveryData.packedDate,
      },
      {
        message: "On the way",
        status: "inactive",
        statusDetails: orderDeliveryData.onTheWayDate,
      },
      {
        message: "Delivered",
        status: "inactive", //cancelled, completed, inactive
        statusDetails: orderDeliveryData.deliveryDate,
      },
    ],
    productSummary: {
      estimatedSubtotal: manageOrderDetails?.subTotal,
      deliverCharges: manageOrderDetails && manageOrderDetails?.deliveryCharge,
      vatCharges:
        manageOrderDetails && manageOrderDetails?.actualVat > 0
          ? manageOrderDetails?.actualVat
          : manageOrderDetails?.vatTax,
      vatPercentage:
        manageOrderDetails?.actualVat != 0 || manageOrderDetails?.vatTax != 0
          ? "05"
          : "", // how to calculate ??
      estimatedAmount: manageOrderDetails?.total,
      recievedAmount: manageOrderDetails?.total, // recieved amount ?
      totalItem:
        manageOrderDetails &&
        manageOrderDetails?.totalItems &&
        manageOrderDetails?.totalItems?.count,
      modifiedAmount: manageOrderDetails?.actualAmount || 0,
      totalAmount: manageOrderDetails?.total || 0,
      modifiedSubtotal: manageOrderDetails?.actualSubtotal,
    },
  };
  const currentOrderStatus =
    orderDeliveryData &&
    orderDeliveryData?.status &&
    orderDeliveryData?.status?.toLowerCase();
  const statusIdx = orderStatus?.status?.findIndex(
    (each) => each.message.toLowerCase() === currentOrderStatus?.toLowerCase()
  );
  orderStatus?.status.forEach((each, index) => {
    if (currentOrderStatus === "placed") {
      if (index === statusIdx) {
        each.status = "completed";
      } else if (statusIdx + 1 === index) {
        each.status = "active";
      } else {
        each.status = "inactive";
      }
    } else if (currentOrderStatus === "delivered") {
      each.status = "completed";
    } else {
      if (currentOrderStatus === "confirmed") {
        if (each.message === "Packed") {
          each.message = "Packaging";
        }
      }
      if (index <= statusIdx) {
        each.status = "completed";
      } else if (index === statusIdx + 1) {
        each.status = "active";
      } else {
        each.status = "inactive";
      }
    }
  });
  const buyerDetails = [
    {
      productKey: "Buyer name",
      productDescription: manageOrderDetails && manageOrderDetails?.buyerName,
    },
    {
      productKey: "POC contact",
      productDescription:
        manageOrderDetails &&
        `${
          manageOrderDetails?.buyerPhoneNum?.split("#").join("-") ??
          "N/A" ??
          "N/A"
        }`,
    },
    {
      productKey: "Buyer POC",
      productDescription: manageOrderDetails && manageOrderDetails?.buyerPOC,
    },
    {
      productKey: "Order value",
      productDescription: `AED ${
        manageOrderDetails && Number(manageOrderDetails?.subTotal)?.toFixed(2)
      }`,
    },
    {
      productKey: "Email",
      productDescription: manageOrderDetails && manageOrderDetails?.buyerEmail,
    },
    {
      productKey: "Payment status",
      productDescription:
        manageOrderDetails && manageOrderDetails?.paidStatus === "not_paid"
          ? "Not Paid"
          : "Paid",
    },
    {
      productKey: "Placed On",
      productDescription: manageOrderDetails?.placedAt,
    },
    {
      productKey: "Mode of payment",
      productDescription: manageOrderDetails && manageOrderDetails?.paymentMode,
    },
    {
      productKey: "Delivery Address (Outlet)",
      productDescription:
        manageOrderDetails && manageOrderDetails?.deliveryAddresss,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [buyerModalOpen, setbuyerModalOpen] = useState<boolean>(false);

  const handleOpen = () => {
    getRatingDetails();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handlebuyerModalOpen = () => {
    // getRatingDetails();
    setbuyerModalOpen(true);
  };
  const handlebuyerModalClose = () => setbuyerModalOpen(false);
  const breadcrumbItems = [
    { label: "Manage Orders", link: routeConfig.orderListingCatalogue },
    { label: manageOrderDetails && manageOrderDetails?.orderPO },
  ];

  const handleModifyOrderModalClose = () => {
    dispatch(setModifyOrdersModal(false));
  };

  const [dataTable, setDataTable] = useState([]);
  useEffect(() => {
    if (manageOrderDetails && manageOrderDetails?.orderedItemsList) {
      setDataTable(manageOrderDetails?.orderedItemsList);
    }
  }, [manageOrderDetails]);

  //api to download the order as pdf file
  const downnloadOrderApi = useAxios({
    axiosParams: {
      url: `store/master-order/download-po/${orderId}`,
      responseType: "arraybuffer",
    },
    method: APIMethods.get,
  });
  const downloadOrder = async () => {
    dispatch(updateIsLoading(true));
    const response: any = await downnloadOrderApi?.fetchData();
    dispatch(updateIsLoading(false));

    if (response) {
      downloadPDF(response);
    }
  };
  const downloadPDF = (data: ArrayBuffer) => {
    // Create a Blob from the binary data
    const blob = new Blob([data], { type: "application/pdf" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${manageOrderDetails?.orderPO?.replace(" ", "_")}.pdf`;

    // Append the link to the document and trigger the download
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <>
      <div className={styles.parent}>
        <div className={styles.outerContainer}>
          <div className={styles.buttonOuterContainer}>
            <Breadcrumb items={breadcrumbItems} />
            <div
              className={
                currentOrderStatus === "delivered"
                  ? styles.buttonContainerDelivery
                  : styles.buttonInnerContainer
              }
            >
              <Button label="Download order" link handleClick={downloadOrder} />
              {currentOrderStatus === "delivered" &&
              manageOrderDetails?.rating !== null ? (
                <Button
                  label="View order rating"
                  link
                  handleClick={handleOpen}
                />
              ) : null}
              {buyerNote?.length > 0 ? (
                <Button
                  label="View buyer note"
                  link
                  handleClick={handlebuyerModalOpen}
                />
              ) : (
                <></>
              )}
              <Button
                label="Contact support"
                link
                handleClick={(event: any) => {
                  event.stopPropagation();
                  setShowContactSupport(!showContactSupport);
                }}
              />
              {showContactSupport && (
                <ContactSupport
                  setCloseContactSupport={setShowContactSupport}
                ></ContactSupport>
              )}
            </div>
          </div>
          <div className={styles.mainContainer}>
            {/* order tracker */}
            <PackageTracker orderStatus={{ ...orderStatus }} />

            {/* buyer detals */}
            <BuyerDeliveryDetails
              orderId={orderId}
              buyerDetails={buyerDetails}
              productSummary={orderStatus.productSummary}
              currentOrderStatus={currentOrderStatus}              
              orderStatus={manageOrderDetails?.orderStatus}
              orderStatusDetails={orderDeliveryData}
              paymentStatus={
                manageOrderDetails && manageOrderDetails?.paidStatus
              }
            />
            {/* table */}
            {dataTable.length > 0 && (
              <div className={styles.productListContainer}>
                <h5>Items Quantity: {dataTable.length} items</h5>
                <div className={styles.tableContainer}>
                  <table>
                    <thead>
                      <th>Product code</th>
                      <th>Product name</th>
                      <th>Product brand</th>
                      <th>Order items</th>
                      <th>Line item amount</th>
                      {currentOrderStatus === "delivered" ? (
                        <th>Updated amount</th>
                      ) : null}
                    </thead>
                    <tbody>
                      {dataTable &&
                        dataTable.length > 0 &&
                        dataTable.map((item: any) => (
                          <tr key={item?.id}>
                            <td>{item?.productCode}</td>
                            <td>{item?.productName}</td>
                            <td>{item?.productBrand}</td>
                            <td>{item?.orderItems}</td>
                            <td>{item?.itemAmount}</td>
                            {currentOrderStatus === "delivered" ? (
                              <td>{item?.updatedAmount}</td>
                            ) : null}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose} className={styles.ratingModal}>
        <div className={styles.modalFullContainer}>
          <div className={styles.modalBody}>
            <div className={styles.heading}>
              <div className={styles.leftContainer}>Order Review</div>
              <div className={styles.rightContainer}>
                <img src={cancelAction} alt="" onClick={handleClose} />
              </div>
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.body}>
              <div className={styles.ratingContainer}>
                <div className={styles.ratingBox}>
                  <div className={styles.upContainer}>
                    <div className={styles.ratingValue}>
                      <span>{orderReview?.orderRating}</span>
                      <span>&nbsp;/&nbsp;</span>
                      <span>5</span>
                    </div>
                    <div className={styles.ratingStarContainer}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <img
                          key={index}
                          src={
                            index < orderReview?.orderRating ? Star : blankStar
                          }
                          alt=""
                        />
                      ))}
                    </div>
                  </div>
                  <div className={styles.ratingBlock}>Overall rating</div>
                </div>
                <div className={styles.ratingBox}>
                  <div className={styles.upContainer}>
                    <div className={styles.ratingValue}>
                      <span>{orderReview?.productRating}</span>
                      <span>&nbsp;/&nbsp;</span>
                      <span>5</span>
                    </div>
                    <div className={styles.ratingStarContainer}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <img
                          key={index}
                          src={
                            index < orderReview?.productRating
                              ? Star
                              : blankStar
                          }
                          alt=""
                        />
                      ))}
                    </div>
                  </div>
                  <div className={styles.ratingBlock}>Product quality</div>
                </div>
              </div>
              <div className={styles.questionContainer}>
                <div className={styles.heading}>
                  {/* Which items do you regret ordering ? */}
                  {getQuestionFromOrderRating(
                    orderReview?.orderRating,
                    orderReview?.productRating
                  )}
                </div>
                <div className={styles.options}>
                  {orderReview?.orderListItems.map(
                    (item: { title: string; variant_id: string | number }) => {
                      const isChecked = orderReview?.ratedItems?.includes(
                        item?.variant_id
                      );
                      return (
                        <div
                          className={`${styles.option} ${styles.viewMode}`}
                          key={item?.variant_id}
                        >
                          <CheckboxButton
                            checked={isChecked}
                            handleChange={() => {}}
                          />
                          <label className={styles.label}>{item?.title}</label>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className={styles.textAreaContainer}>
                {orderReview?.description && (
                  <textarea readOnly>{orderReview?.description}</textarea>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              label="Cancel"
              handleClick={handleClose}
              width="100px"
              customClass={styles.button}
            />
          </div>
        </div>
      </Modal>
      {/* buyer note modal  starts*/}
      <Modal
        open={buyerModalOpen}
        onClose={handlebuyerModalClose}
        className={styles.ratingModal}
      >
        <div className={styles.modalFullContainer}>
          <div className={styles.modalBody}>
            <div className={styles.heading}>
              <div className={styles.leftContainer}>Buyer Note</div>
              <div className={styles.rightContainer}>
                <img
                  src={cancelAction}
                  alt=""
                  onClick={handlebuyerModalClose}
                />
              </div>
            </div>
            <div className={styles.seperator}></div>
            <div className={styles.body}>
              <div className={styles.buyerNote}>{buyerNote}</div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              label="Cancel"
              handleClick={handlebuyerModalClose}
              width="100px"
              customClass={styles.button}
            />
          </div>
        </div>
      </Modal>
      {/* buyer note modal ends*/}
      {/* Modilfy orders modal */}
      {
        <Modal
          open={showModifyModal}
          onClose={() => {
            handleModifyOrderModalClose();
          }}
          className={styles.modifyOrder}
        >
          <ModifyOrders
            customerId={manageOrderDetails?.buyerId}
            orderId={orderId}
            dataTable={manageOrderDetails?.initialModifyOrdersData}
            currentOrderStatus={currentOrderStatus}
            minimumOrderValue={manageOrderDetails?.miniMumOrderValue}
          />
        </Modal>
      }
    </>
  );
};

export default ManageOrders;
