import { GridColDef } from "@mui/x-data-grid";

export const addFavouriteColumns: GridColDef[] = [
  {
    field: "objectId",
    headerName: "Id",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "brandName",
    headerName: "Brand Name",
    width: 150,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "supplier",
    headerName: "Supplier",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "subCategory",
    headerName: "Sub Category",
    width: 200,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "packaging",
    headerName: "Packaging",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
];
