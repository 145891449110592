import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { urlConstants } from "../../../../common/constants/urlConstants";

type ApiResponse<T> = {
  data: T;
  status: string;
  message: string;
};
export class ApiService {
  public loading: boolean;
  private error: string | null;

  constructor() {
    this.loading = false;
    this.error = null;
  }

  public async callApi<T>({
    ...config
  }: AxiosRequestConfig): Promise<ApiResponse<T> | null> {
    this.loading = true;
    this.error = null;

    try {
      const response: AxiosResponse<ApiResponse<T>> = await axios({
        ...config,
        url: `${urlConstants.BASE_URL}${config.url}`,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.message);
        this.error = error.message;
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        }
      } else {
        console.error("Unexpected error:", error);
        this.error = "Unexpected error occurred";
      }
      return null;
    } finally {
      this.loading = false;
    }
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public getError(): string | null {
    return this.error;
  }
}
