import React, { useRef, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import InputField from "../../../common/components/formFields/inputField/inputField";
import SampleFileButton from "./SampleFileButton/SampleFileButton";
import DownloadResults from "./DownloadResults/DownloadResults";
import ResultsTable from "./ResultsTable/ResultsTable";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import styles from "./AlgoliaSearch.module.scss";
import { constants } from "../../../common/constants/constants";
import { ApiService } from "./customApiService/customApiService";
import { getCsvValuesByHeader, readXlsxFile } from "../../../utils/utils";
import {
  AlgoliaSearchResponse,
  IAlgoliaSearchResults,
} from "./algoliaSearch.type";
import { algoliaSearchColumns } from "./columns";

const AlgoliaSearchForm = () => {
  const [queries, setQueries] = useState(1);
  const [inputValues, setInputValues] = useState<string[]>([""]);
  const [reportData, setReportData] = useState<IAlgoliaSearchResults[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: string }>(
    {}
  );
  const [showOffline, setShowOffline] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [loading, setLoading] = useState(false);
  const columns = [...algoliaSearchColumns];
  const selectColumn: GridColDef = {
    field: "select",
    headerName: "Select",
    width: 60,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => (
      <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
        <Checkbox
          key={params.row.objectId as string}
          checked={
            !!selectedRows[params.row.query] &&
            selectedRows[params.row.query] === params.row.objectId
          }
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows((prevSelectedRows) => ({
                ...prevSelectedRows,
                [params.row.query]: params.row.objectId as string,
              }));
            } else {
              setSelectedRows((prevSelectedRows) => {
                const newSelectedRows = { ...prevSelectedRows };
                delete newSelectedRows[params.row.query];
                return newSelectedRows;
              });
            }
          }}
        />
      </div>
    ),
  };

  columns.unshift(selectColumn);

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const fetchAlgoliaData = new ApiService();

  const fetchAlogoliaResults = async (
    values: string[],
    targetPrices: { [key: string]: string } = {},
    limits: string[] = [],
    unitMeasure: string[] = [],
    distribution: string[] = [],
    packaging: { [key: string]: string } = {}
  ) => {
    setLoading(true);
    let queriesWithLimits: { query: string; limit: string }[] = [];
    if (limits.length) {
      queriesWithLimits = values.map((query, index) => ({
        query,
        limit: limits[index],
        unitMeasure: unitMeasure[index],
        distribution: distribution[index],
      }));
    }

    const data = await fetchAlgoliaData.callApi<AlgoliaSearchResponse>({
      method: "post",
      url: "store/algolia/get-algolia-search-result?type=search",
      data: {
        queries: limits.length
          ? queriesWithLimits
          : values.filter(Boolean).map((query) => ({ query })),
        offline: showOffline,
        deactivated: showDeactivated,
      },
    });
    const alterdata: IAlgoliaSearchResults[] = Object.entries(data?.data || {})
      .map(([query, items]) => {
        return items.map((item) => ({
          id: crypto.getRandomValues(new Uint32Array(1))[0].toString(),
          objectId: item.id,
          query,
          title: item.title ?? "N/A",
          supplier: item.supplier ?? "N/A",
          price: item?.price?.map((i) => `AED ${i}`)?.join(", ") ?? "N/A",
          userDefinedPackage: packaging[query] ?? "N/A",
          packaging: item.packaging?.join(", "),
          category: item.category ?? "N/A",
          subCategory: item.subCategory ?? "N/A",
          brandName: item.brandName ?? "N/A",
          targetPrice: targetPrices[query] ?? "N/A",
        }));
      })
      .flat();
    setReportData(alterdata);
    setSelectedRows({});
    setLoading(false);
  };

  const handleSubmit = async (form: React.FormEvent) => {
    form.preventDefault();
    if (inputValues.filter(Boolean).length) {
      await fetchAlogoliaResults(inputValues);
    }
  };

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    let productDetails: Array<string> = [];
    let targetPrices: Array<string> = [];
    let limits: Array<string> = [];
    let packaging: Array<string> = [];
    let unitMeasure: Array<string> = [];
    let distribution: Array<string> = [];
    const file = e.target.files?.[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel" ||
        fileType === "text/csv"
      ) {
        const rows = await readXlsxFile(file);
        const onError = (error: string) => {
          setToastData({
            message: error,
            status: "Error",
          });
        };
        targetPrices = getCsvValuesByHeader(rows, "target price", onError).data;
        productDetails = getCsvValuesByHeader(
          rows,
          "Product Details",
          onError
        ).data;
        limits = getCsvValuesByHeader(rows, "Limit", onError).data;
        packaging = getCsvValuesByHeader(rows, "Packaging", onError).data;
        unitMeasure = getCsvValuesByHeader(rows, "Unit Measure", onError).data;
        distribution = getCsvValuesByHeader(rows, "Distribution", onError).data;
      } else {
        console.error("Unsupported file type");
      }
    }
    const productToPrice = productDetails.reduce(
      (acc: { [key: string]: string }, product, index) => {
        acc[product] = targetPrices[index];
        return acc;
      },
      {}
    );
    const productToPackage = productDetails.reduce(
      (acc: { [key: string]: string }, product, index) => {
        acc[product] = packaging[index];
        return acc;
      },
      {}
    );
    setInputValues([""]);
    setQueries(1);
    await fetchAlogoliaResults(
      productDetails,
      productToPrice,
      limits,
      unitMeasure,
      distribution,
      productToPackage
    );
    e.target.value = "";
  };

  return (
    <>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
          handleClose={() => setToastData(constants.defaultToastData)}
        />
      )}
      <Grid container direction="column">
        <h1 className={styles.heading}>Algolia Search Form</h1>
        <form onSubmit={handleSubmit} style={{ paddingLeft: "40px" }}>
          <Grid container flexDirection="column" gap="8px">
            <Grid
              item
              container
              flexDirection="row"
              style={{
                width: "fit-content",
              }}
              gap="10px"
            >
              {Array.from({ length: queries }, (_, i) => (
                <Grid key={i}>
                  <Grid item container className={styles.transparentInput}>
                    <InputField
                      placeholder="Search"
                      id={`Search${i}`}
                      maxWidth="100%"
                      onChange={(e: any) => handleInputChange(i, e)}
                      value={inputValues[i] || ""}
                    />
                  </Grid>
                </Grid>
              ))}
              <Grid container flexDirection="row" gap="6px">
                <Grid item>
                  <button
                    type="button"
                    className={styles.commonBtn}
                    onClick={() => {
                      if (inputValues.every(Boolean)) {
                        setQueries(queries + 1);
                        setInputValues([...inputValues, ""]);
                      }
                    }}
                  >
                    Add Search
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    className={styles.commonBtn}
                    onClick={() => {
                      if (queries > 1) {
                        setQueries(queries - 1);
                        setInputValues(inputValues.slice(0, -1));
                      }
                    }}
                  >
                    Remove Search
                  </button>
                </Grid>
                <Grid item>
                  <Tooltip title="Upload a CSV or xlsx file">
                    <button
                      className={styles.commonBtn}
                      type="button"
                      onClick={handleFileUploadClick}
                      disabled={loading}
                    >
                      Upload File
                    </button>
                  </Tooltip>
                  <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    className={styles.commonBtn}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={onUpload}
                  />
                </Grid>
                <Grid item>
                  <SampleFileButton />
                </Grid>
                <Grid item>
                  <DownloadResults
                    columns={columns}
                    reportData={reportData}
                    selectedRows={selectedRows}
                    disabled={!reportData.length || loading}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                label="show deactivated"
                control={
                  <Checkbox
                    checked={showDeactivated}
                    onChange={(e) => setShowDeactivated(e.target.checked)}
                  />
                }
              />
              <FormControlLabel
                label="show offline"
                control={
                  <Checkbox
                    checked={showOffline}
                    onChange={(e) => setShowOffline(e.target.checked)}
                  />
                }
              />
            </Grid>
            <Grid
              item
              style={{
                width: "fit-content",
              }}
            >
              <button
                className={styles.commonBtn}
                type="submit"
                disabled={loading}
              >
                Submit
              </button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <ResultsTable
        columns={columns}
        reportData={reportData}
        loading={loading}
      />
    </>
  );
};

export default AlgoliaSearchForm;
