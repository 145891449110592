import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import image from "../../../../assets/images/orderPacked.svg";
import { updateAdminOrderStatus } from "../../../../reducerSlices/adminManageOrderDetailsSlice";
import { APIMethods, urlConstants } from "../../../constants/urlConstants";
import Button from "../../button/Button";
import styles from "./OrderPackedcard.module.scss";
import { updateOrderStatus } from "../../../../reducerSlices/manageOrderDetails";
import { useAxios } from "../../../../services/useAxios";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { IMarkAsDeliverOfflineOrder } from "../../../../models/IAdminOrdersListingCardProps";
import offlineOrderShipped from "../../../../assets/images/orderShipped.svg";
import { setModifyOrdersModal } from "../../../../reducerSlices/modifyOrdersSlice";

const OrderPackedcard = ({ orderId, isAdmin=false }: any) => {
  const dispatch = useDispatch()
  async function userActionStatus(status: string) {
    try {
      const response: any = await axios.post(`${urlConstants.BASE_URL}store/master-order/${orderId}/track-order`, {
        status,
      });
      if (response && (response?.status === "SUCCESS" || response?.status === 200)) {
        // admin orders
        if (isAdmin && status) {
          dispatch(updateAdminOrderStatus({ status } as any));
        }
        //  supplier orders
        else if(!isAdmin && status){
          dispatch(updateOrderStatus({ status } as any));
        }
      }
    } catch (error) {
      console.log("err in 3", error);
    }
  }
  const handleReadyToShip = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(updateIsLoading(true));
    await userActionStatus("shipped");
    dispatch(updateIsLoading(false));
  };
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={image} alt="placedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          <div className={styles.cardTitle}>Order packed!</div>
          <div className={styles.cardSubTitle}>
            Ready to dispatch? click below.!
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button label="Ship order" handleClick={handleReadyToShip}></Button>
        </div>

        <div className={styles.buttonContainer}>
            <Button
              label="Modify order"
              handleClick={() => {
                dispatch(setModifyOrdersModal(true));
              }}
            ></Button>
          </div>
      </div>
     
    </div>
  );
};

export default OrderPackedcard;



export const MarkAsDeliverOfflineOrder = ({ orderId, isAdmin=false }:IMarkAsDeliverOfflineOrder) => {
  const dispatch = useDispatch();
  const { adminManageOrderDetails } = useSelector((state: any) => state);
  const markAsDelivered = useAxios({
    axiosParams:{
      url:`store/master-order/${orderId}/delivered`,
      data:adminManageOrderDetails?.markAsReceivedPayload
    },
    method:APIMethods.post
  })
  const markAsDeliveredOfflineOrderApi = async () => {
    try {
      if (
        orderId &&
        isAdmin &&
        adminManageOrderDetails?.orderType === "Offline" &&
        adminManageOrderDetails?.markAsReceivedPayload &&
        Object.keys(adminManageOrderDetails?.markAsReceivedPayload)?.length > 0
      ) {
        dispatch(updateIsLoading(true));
        const response: any = await markAsDelivered?.fetchData();
        if (response && response?.status === "SUCCESS") {
          dispatch(updateAdminOrderStatus({ status: "delivered" } as any));
        }
      }
    } catch (error) {
      console.log("mark as recived api failed", error);
    } finally {
      dispatch(updateIsLoading(false));
    }
  };
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={offlineOrderShipped} alt="Shippedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          <div className={styles.cardTitle}>Order shipped!</div>
          <div className={styles.cardSubTitle}>
          Sit back & relax, the order is on the move! It's been shipped and is
            now out for delivery.
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button label="Mark as delivered" handleClick={markAsDeliveredOfflineOrderApi}></Button>       
        </div>
        <div className={styles.buttonContainer}>
            <Button
              label="Modify order"
              handleClick={() => {
                dispatch(setModifyOrdersModal(true));
              }}
            ></Button>
          </div>    
      </div>
    </div>
  );
};