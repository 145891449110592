import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import styles from "./OfflineSupplierFormStyles.module.scss";
import { routeConfig } from "../../../../common/constants/routeConfig";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import useFetchCountries from "../../../../services/useCountry";
import { ICountry } from "../../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../../services/useAxios";
import { setToastStatusApproval } from "../../../../reducerSlices/toastStatusSlice";
import { useDispatch } from "react-redux";
import MobileNumberField from "../../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";
import { constants } from "../../../../common/constants/constants";
import { useFieldArray } from "react-hook-form";

export const OfflineSupplierForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [editForm, setEditForm] = useState(false);
  const { countriesList } = useFetchCountries();
  const [offlineSupplier, setOfflineSupplier] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [mobileNumberProductCode, setMobileNumberProductCode] = useState<any>();
  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates = selectedOption?.emirates.map((emirate: ICountry) => ({
      ...emirate,
      value: emirate?.name,
    }));
    setEmiratesList(emirates);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
    trigger,
    watch,
    getValues,
    reset,
    control,
  } = useForm();
  const values = getValues();
  const { fields, append, remove } = useFieldArray({
    name: "ccEmails",
    control,
  });

  useEffect(() => {
    setValue("customerName", offlineSupplier?.name);
    setValue("email", offlineSupplier?.email);
    setValue("businessName", offlineSupplier?.business_name);
    setValue("address", offlineSupplier?.address?.address);
    setValue("customerId", offlineSupplier?.id);
    setValue("area", offlineSupplier?.address?.area);
    setValue("country", offlineSupplier?.address?.country?.iso_2);
    setValue("emirates", offlineSupplier?.address?.emirate?.id);
    setValue("customerphone", offlineSupplier?.phone);
    setValue("ccEmails", offlineSupplier?.cc_email_list);
  }, [offlineSupplier, setValue]);

  useEffect(() => {
    if (offlineSupplier?.address) {
      let country = null;
      if (getValues()?.country) {
        country = countriesList?.find(
          (ele: any) => ele?.iso_2 === getValues()?.country
        );
        setSelectedCountry(country);
      }
      if (country && getValues()?.emirates) {
        setSelectedEmirate(
          country?.emirates?.find(
            (ele: any) => ele?.id === getValues()?.emirates
          )
        );
      }
    }
  }, [offlineSupplier, countriesList, getValues]);
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      isSubmitted && trigger(name); // Re-validate field if the form is already submitted
    },
    [setValue, isSubmitted, trigger]
  );

  const updateOfflineSupplier = useAxios({
    axiosParams: {
      url: `store/offlinesupplier/${offlineSupplier?.business_id}/customer/${customerId}`,
      data: {
        first_name: values?.customerName,
        email: values?.email,
        business_name: values?.businessName,
        customerphone: values.customerphone,
        cc_email_list:values?.ccEmails,
        supplier_address: [
          {
            country: {
              iso_2: selectedCountry?.iso_2,
            },
            address: values?.address,
            emirate: {
              id: selectedEmirate?.id,
            },
            area: values?.area,
          },
        ],
      },
    },
    method: "PUT",
  });

  const { fetchData } = useAxios({
    axiosParams: {
      url: `store/offlinesupplier/${customerId}`,
      method: "GET",
    },
  });
  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(updateIsLoading(true));
        const response: any = await fetchData();
        setOfflineSupplier(response?.data);
        dispatch(updateIsLoading(false));
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    getData();
  }, [customerId]);
  // Handle form submission
  const onSubmit = async (data: any) => {
    const response: any = await updateOfflineSupplier.fetchData();
    if (response.status === "SUCCESS") {
      dispatch(
        setToastStatusApproval({
          message: "Offline Supplier has been updated successfully",
          status: "new",
          type: "offline-suppliers",
        } as any)
      );
      reset();
      navigate(routeConfig.offlineSuppliers);
    } else {
      console.error("Error", response?.response?.data?.error_msg);
    }
  };

  return (
    <>
      <div className={styles.supplierForm}>
        <div className={styles.right}>
          <div className={styles.top}>
            <div className={styles.greetings}>
              <div className={styles.user}>Edit Offline Supplier Form</div>
            </div>
            <div className={styles.actionsOnUser}>
              {!editForm && (
                <button
                  className={styles.submit}
                  type="button"
                  onClick={() => setEditForm(!editForm)}
                >
                  Edit Form
                </button>
              )}
            </div>
          </div>
          <form className={styles.bottom} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <div className={styles.businessSetup}>
                <div className={styles.inputFields}>
                  <div className={styles.inputField}>
                    <InputField
                      id="customerName"
                      label="Name"
                      maxWidth="100%"
                      minWidth="250px"
                      placeholder="Enter Name"
                      disabled={!editForm}
                      value={watch("customerName")}
                      onChange={onChangeField("customerName")}
                      register={register("customerName", {
                        required: "Name is required",
                      })}
                      errorMessage={errors.customerName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      id="email"
                      label="Email"
                      disabled={!editForm}
                      placeholder="Enter Email"
                      value={watch("email")}
                      onChange={onChangeField("email")}
                      register={register("email", {
                        required: "Email is required",
                      })}
                      errorMessage={errors.email?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      id="businessName"
                      label="Business Name"
                      placeholder="Enter Business Name"
                      disabled={!editForm}
                      value={watch("businessName")}
                      onChange={onChangeField("businessName")}
                      register={register("businessName", {
                        required: "Business Name is required",
                      })}
                      errorMessage={errors.businessName?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <MobileNumberField
                      defaultValue={
                        mobileNumberProductCode
                          ? mobileNumberProductCode
                          : countriesList &&
                            countriesList.length > 0 &&
                            countriesList.filter(
                              (country) =>
                                country?.dial_codes === ("+971" as any)
                            )[0]
                      }
                      width="100%"
                      overlayWidth={"300px"}
                      value={watch("customerphone")}
                      label="Phone number"
                      id={"phnNo"}
                      options={countriesList}
                      editForm={!editForm}
                      registerMobileNumber={register("customerphone")}
                      registerCountry={register("countryName")}
                      onMobileNumberChange={onChangeField("customerphone")}
                      onCountryChange={(selectedOption: ICountry) => {
                        onChangeField("countryName")(selectedOption);
                        setMobileNumberProductCode(selectedOption);
                      }}
                      mobileNumberField={true}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      id="address"
                      disabled={!editForm}
                      label="Address"
                      placeholder="Enter Address"
                      value={watch("address")}
                      onChange={onChangeField("address")}
                      register={register("address", {
                        required: "Address is required",
                      })}
                      errorMessage={errors.address?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      maxWidth="100%"
                      minWidth="250px"
                      value={watch("area")}
                      id="area"
                      disabled={!editForm}
                      label="Area"
                      placeholder="Enter area"
                      onChange={onChangeField("area")}
                      register={register("area")}
                      errorMessage={errors.area?.message}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Dropdown
                      id={"country"}
                      label="Country"
                      editForm={!editForm}
                      options={
                        countriesList &&
                        countriesList.filter(
                          (country: ICountry) => country?.iso_2 === "ae"
                        )
                      }
                      minWidth="100%"
                      isDropdownOutlineRequired={true}
                      register={register("country")}
                      onChange={(selectedOption: ICountry) => {
                        onChangeField("country")(selectedOption);
                        setSelectedCountry(selectedOption);
                        handleCountryClick(selectedOption);
                      }}
                      defaultLabel={selectedCountry?.name ?? "Select country"}
                      errorMessage={(errors.country as any)?.message}
                      selectedValue={selectedCountry}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <Dropdown
                      id={"Emirate"}
                      label="Emirate"
                      editForm={!editForm}
                      options={emiratesList}
                      minWidth="100%"
                      isDropdownOutlineRequired={true}
                      register={register("emirates")}
                      onChange={(selectedOption: ICountry) => {
                        onChangeField("emirates")(selectedOption);
                        setSelectedEmirate(selectedOption);
                      }}
                      defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                      errorMessage={(errors.emirates as any)?.message}
                      selectedValue={selectedEmirate}
                    />
                  </div>
                  {/* CC_email_list */}
                  <div className={styles.additionalEmailsContainer}>
                  <div className={styles.businessSetup}>
                    <div className={styles.setup}>
                      {constants.BusinessSetupForm.additionalEmails}
                    </div>
                    <div className={styles.content}>
                      {constants.BusinessSetupForm.additionalEmailsContent}
                    </div>
                    <div className={styles.inputFields}>
                      {fields.map((field, index) => (
                        <div
                          style={{ display: "flex", gap: "12px",alignItems:"center" }}
                          key={field.id}
                        >
                          <div
                            className={`${styles.inputField} ${styles.addressField}`}
                          >
                            <InputField
                              id={`ccEmails.${index}.email`}
                              label="Email"
                              placeholder="Enter Email"
                              disabled={!editForm}
                              value={watch(`ccEmails.${index}.email`)}
                              onChange={(text: any) => {
                                setValue(`ccEmails.${index}.email`, text);
                                trigger(`ccEmails.${index}.email`);
                              }}
                              register={register(`ccEmails.${index}.email`, {
                                required: "Email is required",
                                pattern: {
                                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                  message: "Invalid email format",
                                },
                              })}
                              errorMessage={
                                (errors.ccEmails &&
                                  Array.isArray(errors.ccEmails) &&
                                  errors.ccEmails[index]?.email?.message) ||
                                undefined
                              }
                            />
                          </div>

                          <div className={styles.action}>
                            <button
                              type="button"
                              className={styles.skip}
                              onClick={() => remove(index)}
                            >
                              Remove Email
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.action}   style={{ alignSelf: "flex-start" }}>
                      <button
                        type="button"
                        className={`${styles.submit} ${styles.outletBtn}`}
                        disabled={!editForm}
                        onClick={() => append({ email: "" })}
                      >
                        Add Email
                      </button>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.action}>
              <button
                className={styles.skip}
                onClick={() => navigate(routeConfig.offlineSuppliers)}
              >
                Cancel
              </button>
              <button className={styles.submit} type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
