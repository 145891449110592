import axios from "axios";
import { useDispatch } from "react-redux";
import image from "../../../../assets/images/orderPlaced.svg";
import { OrderDeliveryDetails, updateAdminOrderStatus } from "../../../../reducerSlices/adminManageOrderDetailsSlice";
import { urlConstants } from "../../../constants/urlConstants";
import Button from "../../button/Button";
import styles from "./OrderPlacedcard.module.scss";
import { updateOrderStatus } from "../../../../reducerSlices/manageOrderDetails";
import { setModifyOrdersModal } from "../../../../reducerSlices/modifyOrdersSlice";
import { updateIsLoading } from "../../../../reducerSlices/loaderSlice";

const OrderPlacedCard = ({ orderId, isAdmin = false, orderStatus,orderStatusDetails }: any) => {
  const dispatch = useDispatch();
  async function userActionStatus(status: string) {
    try {
      if (orderId) {
        const response: any = await axios.post(
          `${urlConstants.BASE_URL}store/master-order/${orderId}/track-order`,
          {
            status,
          }
        );
        if (
          response &&
          (response?.status === "SUCCESS" || response?.status === 200)
        ) {
          // admin orders
          if (isAdmin && status) {
            dispatch(updateAdminOrderStatus({ status } as any));
          }
          //  supplier orders
          else if (!isAdmin && status) {
            dispatch(updateOrderStatus({ status } as any));
          }
        }
      }
    } catch (error) {
      console.log("err in 3", error);
    }
  }

  const getNextOrderStatus = (item:OrderDeliveryDetails) =>{
   if (item?.packedDate !== null && item?.packedDate !== "-") {
      return "shipped";
    } else if (item?.confirmedDate !== null && item?.confirmedDate !== "-") {
      return "shipped";
    } else if (item?.placedDate !== null && item?.placedDate !=="-" ) {
      return "confirmed";
    } else {
      return "placed";
    }
  }

  const handleAccept = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(updateIsLoading(true));
    const nextOrderStatus = getNextOrderStatus(orderStatusDetails)
    await userActionStatus(nextOrderStatus);
    dispatch(updateIsLoading(false));
  };
  const handleReject = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(updateIsLoading(true));
    await userActionStatus("canceled");
    dispatch(updateIsLoading(false));
  };
  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={image} alt="placedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          {}
          <div className={styles.cardTitle}>
            {orderStatus && orderStatus === "modified"
              ? "Order Modified!"
              : "New order placed!"}
          </div>
          <div className={styles.cardSubTitle}>
            Ready, set, fulfill the request!
          </div>
        </div>
        {/* {isAdmin && ( */}
        <div className={styles.buttonContainer}>
          <Button
            label={
              orderStatus && orderStatus === "modified"
                ? "Reject"
                : "Reject order"
            }
            secondary
            handleClick={handleReject}
          ></Button>
          <Button
            label={
              orderStatus && orderStatus === "modified"
                ? "Accept"
                : "Accept order"
            }
            handleClick={handleAccept}
          ></Button>
        </div>
        {/* )} */}
        {orderStatus !== "modified" && (
          <div className={styles.newButtonContainer}>
            <Button
              label="Modify order"
              handleClick={() => {
                dispatch(setModifyOrdersModal(true));
              }}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderPlacedCard;
