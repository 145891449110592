import React, { ComponentPropsWithRef } from "react";
import { addFavouriteResults } from "./addFavourite.type";
import styles from "./addFavourite.module.scss";

type InvalidIdDownloadProps = ComponentPropsWithRef<"button"> & {
  data: addFavouriteResults[];
};

function InvalidIdDownload({ data, ...props }: InvalidIdDownloadProps) {
  const handleDownloadCSV = () => {
    const dataForDownload = [
      ["Invalid Ids"],
      ...data.map((item) => [item["objectId"]]),
    ];
    const csvContent = dataForDownload.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}_${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
      now.getSeconds()
    ).padStart(2, "0")}`;
    link.setAttribute("download", `not_found_product_ids_${formattedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className={styles.commonBtn}
      type="button"
      onClick={handleDownloadCSV}
      {...props}
    >
      Download invalid Ids
    </button>
  );
}

export default InvalidIdDownload;
