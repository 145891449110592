import { IRouteComponents } from "../../models/IRouteComponents";
import AdminLinkProduct from "../../pages/admin/AdminLinkProduct/AdminLinkProduct";
// import AdminAddIndividual from "../../pages/admin/AdminAddIndividual/AdminAddIndividual";
import AdminBuyerBusinessSetupForm from "../../pages/admin/adminBuyerForm/adminBuyerForm";
import AdminAddSupplier from "../../pages/admin/adminDashboard/AdminAddSupplier";
import AdminAddUser from "../../pages/admin/adminDashboard/AdminAddUser";
import AdminManageCatalogue from "../../pages/admin/adminDashboard/adminManageCatalogue/AdminManageCatalogue";
import { AdminManageOrders } from "../../pages/admin/adminDashboard/adminManageOrders/AdminManageOrders";
import AdminLevelForm from "../../pages/admin/adminLevelForm/AdminLevelForm";
import AdminOrderDetails from "../../pages/admin/adminOrderDetails/AdminOrderDetails";
import AdminSupplierBusinessSetupForm from "../../pages/admin/adminSupplierSetupForm/AdminSupplierSetupForm";
import { BuyerBusinessApprovalPage } from "../../pages/admin/buyerBusinessApprovalProcess/BuyerBusinessApprovalPage";
// import BuyerBusinessApprovalForm from "../../pages/admin/buyerBusinessApprovalProcess/components/BusinessApprovalForm/BuyerBusinessApprovalForm";
// import SupplierBusinessApprovalForm from "../../pages/admin/supplierBusinessApprovalProcess/BusinessApprovalForm/SupplierBusinessApprovalForm";
import { SupplierBusinessApprovalPage } from "../../pages/admin/supplierBusinessApprovalProcess/SupplierBusinessApprovalPage";
import { SupplierProducsApprovalProcess } from "../../pages/admin/supplierProductsApprovalProcess/SupplierProductsApprovalProcess";
import { ProductApprovalTabs } from "../../pages/admin/supplierProductsApprovalProcess/productsApprovalTabs/ProductsApprovalTabs";
import { DashBoard } from "../../pages/buyer/dashboard/Dashboard";
import AccountVerification from "../../pages/buyer/login/accountVerification/AccountVerification";
import { ForgotPassword } from "../../pages/buyer/login/forgotPassword/ForgotPassword";
import ResetCompleted from "../../pages/buyer/login/resetCompleted/ResetCompleted";
import { ResetLink } from "../../pages/buyer/login/resetLink/ResetLink";
import { ResetPassword } from "../../pages/buyer/login/resetPassword/ResetPassword";
import SignInForm from "../../pages/buyer/login/signIn/signIn";
import SignUpForm from "../../pages/buyer/login/signup/signUp";
import AddIndividual from "../../pages/buyer/manageCatalogue/AddIndividual/AddIndividual";
import ManageCatalogue from "../../pages/buyer/manageCatalogue/ManageCatalogue";
import MarketPlace from "../../pages/buyer/marketPlace/MarketPlace";
import ProductListingPage from "../../pages/productListing/ProductListingPage";
import SupplierDashBoard from "../../pages/supplier/Dashboard/DashBoard";
import InVoicesPage from "../../pages/supplier/InVoices/InVoicesPage";
import ManageOrders from "../../pages/supplier/ManageOrders/ManageOrders";
import BusinessSetupForm from "../../pages/supplier/onBoardingDashboard/businessSetupForm/BusinessSetupForm";
import SupplierProductDetailsPage from "../../pages/supplier/supplierProductDetails/SupplierProductDetailsPage";
import InvoiceDetails from "../components/invoiceDetails/InvoiceDetails";
import LoadingScreen from "../pages/loadingScreen/LoadingScreen";
import { OrdersListing } from "../pages/ordersListing/OrdersListing";
import { ProductsListing } from "../pages/productsListing/ProductsListing";
import Report from "../pages/report/Report";
import SpecialPricing from "../pages/specialPricing/SpecialPricing";
import { routeConfig } from "./routeConfig";
import SupplierFullyLoadedDashboard from "../../pages/supplier/SupplierFullyLoadedDashboard/SupplierFullyLoadedDashboard";
import Notifications from "../../pages/supplier/notifications/notifications";
// import CreateRequest from "../pages/specialPricing/components/createRequest/CreateRequest";
import BuyerBusinessSetupForm from "../../pages/buyer/onboardingDashboard/businessSetupForm/BuyerBusinessSetupForm";
import PlaceOrder from "../../pages/buyer/myCart/placeOrder";
import MyCart from "../../pages/buyer/myCart";
import SubCategoryItemsList from "../../pages/buyer/marketPlace/subCategoryItemsList/SubCategoryItemsList";
import ProductDetailsPage from "../../pages/productDetails/ProductDetailsPage";
import ProductsListAndFilters from "../../pages/buyer/marketPlace/productsListAndFilters/ProductsListAndFilters";
import PreferencesListing from "../../pages/buyer/preferences/preferencesListing/preferencesListing";
import SupplierPreferences from "../../pages/supplier/supplierPreferences/SupplierPreferences";
import OfflineSuppliersList from "../../pages/admin/offlineSuppliers/OfflineSuppliers";
import { OfflineSupplierForm } from "../../pages/admin/offlineSuppliers/OfflineSupplierForm/OfflineSupplierForm";
import AddNewSupplier from "../../pages/buyer/addNewSupplier/AddNewSupplier";
import CreateBulkUpload from "../components/bulkUpload/createProducts/CreateBulkUpload";
import EditBulkUpload from "../components/bulkUpload/editProducts/EditBulkUpload";
import ModifyBulkProduct from "../../pages/buyer/manageCatalogue/modifybulkProduct/ModifyBulkProduct";
import DownloadManageCatalogue from "../../pages/buyer/manageCatalogue/modifybulkProduct/downloadManageCatalogue/DownloadManageCatlogue";
import CreateSpecialPriceRequest from "../pages/specialPricing/components/createSpecialPriceRequest/CreateSpecialPriceRequest";
import CustomerListing from "../../pages/admin/customersListing/CustomerListing";
import AdminFinanceForm from "../../pages/admin/adminFinanceForm/AdminFinanceForm";
import AlgoliaSearch from "../../pages/supplier/algoliaSearch/algoliaSearch";
import AddFavourite from "../../pages/supplier/addFavourite/addFavourite";

export const componentRoutes: Array<IRouteComponents> = [
  {
    path: routeConfig.dashboard,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <DashBoard />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.marketPlace,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <MarketPlace />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.subCategoryItemsListPage,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SubCategoryItemsList />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.ProductsListAndFiltersPage,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ProductsListAndFilters />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerProductDetails,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerLoadingScreen,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <LoadingScreen />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.produtLinsting,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ProductListingPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminSupplierSetupForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminSupplierBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminBuyerSetupForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminBuyerBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminManageCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminManageOrders,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminManageOrders />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminOrderDetails,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminOrderDetails />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.inVoices,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InVoicesPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerInvoices,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InVoicesPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerInvoiceDetails,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InvoiceDetails />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminManageInvoices,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InVoicesPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.manageSupplierCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminProductAddCards,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.uploadSupplierProductsFromAdmin,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.addIndividualProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.linkIndividualProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminLinkProduct />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.productDetailsPage,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.productDetailsPageAdmin,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.productDetailsPageAdminFromCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.productDetailsPageSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.shareProductDetails,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProductDetailsPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.modifyBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ModifyBulkProduct />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminModifyBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ModifyBulkProduct />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.downloadManageCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <DownloadManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminDownloadManageCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <DownloadManageCatalogue />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.businessSetupForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <BusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerBusinessSetupForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <BuyerBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierDashboard,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierDashBoard />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierOnboardinDashboard,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <BusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.manageOrders,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ManageOrders />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.productListingCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ProductsListing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.orderListingCatalogue,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <OrdersListing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerOrdersListing,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <OrdersListing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.addBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CreateBulkUpload />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.addBulkSupplierProductFromAdmin,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CreateBulkUpload />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminAddBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CreateBulkUpload />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.editBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <EditBulkUpload />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminEditBulkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <EditBulkUpload />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerBusinessApprovalPage,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <BuyerBusinessApprovalPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.invoiceDetails,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InvoiceDetails />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.invoiceDetailsAdmin,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <InvoiceDetails />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierBusinessApprovalPage,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierBusinessApprovalPage />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminAddBuyer,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminAddUser />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.offlineSuppliers,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <OfflineSuppliersList />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.offlineSuppliers,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <OfflineSuppliersList />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminProductApproval,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ProductApprovalTabs />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminProductApprovalForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          {/* <AdminAddIndividual /> */}
          {/* <AdminManageIndividual /> */}
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.linkProduct,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminAddSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminAddSupplier />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerApprovalForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminBuyerBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierApprovalForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminSupplierBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminEditSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminSupplierBusinessSetupForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminProductsApprovalSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierProducsApprovalProcess />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.loadingScreen,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <LoadingScreen />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminLevelForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminLevelForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.updateOfflineSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <OfflineSupplierForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.updateAdminLevelForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminLevelForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminManageCatalogueForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminAddProductForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierProductEditForm,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddIndividual />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierReport,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <Report />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminCustomersList,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CustomerListing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.createFinanceRequest,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AdminFinanceForm />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierFullyLoadedDashboard,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierFullyLoadedDashboard />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.specialPricing,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SpecialPricing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminReport,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <Report />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.notifications,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <Notifications />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.adminSpecialPricing,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SpecialPricing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerSpecialPricing,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SpecialPricing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.AdminSpecialPricingRequest,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CreateSpecialPriceRequest />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierSpecialPricingRequest,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <CreateSpecialPriceRequest />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.myCart,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <MyCart />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.placeOrder,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <PlaceOrder />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerPreferences,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <PreferencesListing />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.supplierPreferences,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SupplierPreferences />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.buyerAddNewSupplier,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddNewSupplier />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.algoliaSearch,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AlgoliaSearch />
        </div>
      </div>
    ),
  },
  {
    path: routeConfig.addFavourite,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AddFavourite />
        </div>
      </div>
    ),
  },
];

export const loginRoutes: Array<IRouteComponents> = [
  {
    path: routeConfig.signup,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SignUpForm />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.signin,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <SignInForm />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.forgotPassword,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ForgotPassword />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.accountVerification,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <AccountVerification />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.resetPassword,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ResetPassword />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.resetLink,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ResetLink />
        </div>
      </div>
    ),
  },

  {
    path: routeConfig.resetCompleted,
    element: (
      <div className="parent_container">
        <div className="content_container">
          <ResetCompleted />
        </div>
      </div>
    ),
  },
];
