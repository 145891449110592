import { read, utils } from "xlsx";

export const getCsvValuesByHeader = (
  rows: Array<string>[],
  header: string,
  onError?: (error: string) => void
) => {
  const headerIndex = rows[0]
    .map((v) => v.toLowerCase())
    .indexOf(header.toLowerCase());
  if (headerIndex === -1) {
    console.error(`Header "${header}" not found`);
    onError && onError(`Header "${header}" not found`);
    return {
      error: `Header "${header}" not found`,
      data: [],
    };
  }
  return { error: null, data: rows.slice(1).map((row) => row[headerIndex]) };
};

export const readXlsxFile = async (file: File): Promise<Array<string>[]> => {
  const data = await file.arrayBuffer();
  const workbook = read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  return utils.sheet_to_json(worksheet, { header: 1 });
};
