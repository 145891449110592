import styles from "./OrderShippedCard.module.scss";
import image from "../../../../assets/images/orderShipped.svg";
import Button from "../../button/Button";
import { setModifyOrdersModal } from "../../../../reducerSlices/modifyOrdersSlice";
import { useDispatch } from "react-redux";
const OrderShippedCard = () => {
  const dispatch = useDispatch()

  return (
    <div>
      <div className={styles.cardContainer}>
        <div className={styles.cardSvgContainer}>
          <img src={image} alt="Shippedimage" />
        </div>
        <div className={styles.cardTitleContainer}>
          <div className={styles.cardTitle}>Order shipped!</div>
          <div className={styles.cardSubTitle}>
            Sit back & relax, the order is on the move! It's been shipped and is
            now out for delivery.
          </div>
          <div className={styles.buttonContainer}>
            <Button
              label="Modify order"
              handleClick={() => {
                dispatch(setModifyOrdersModal(true));
              }}
            ></Button>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default OrderShippedCard;
