import { createContext } from "react";
import { IProductAccordionItem } from "../../../models/IProductAccordionItem";
import { constants } from "../../constants/constants";
import styles from "./BuyerDeliveryDetails.module.scss";
import OrderCancelled from "./orderCancelled/orderCancelled";
import OrderPackedcard, {
  MarkAsDeliverOfflineOrder,
} from "./orderPackedcard/OrderPackedcard";
import OrderPlacedCard from "./orderPlacedcard/OrderPlacedcard";
import OrderShippedCard from "./orderShippedCard/OrderShippedCard";
import OrderSummaryCard from "./orderSummaryCard/OrderSummaryCard";

export const isInvoiceDetailsProvider = createContext(null);
const BuyerDeliveryDetails = (props: any) => {
  const {
    paidStatus,
    buyerDetails,
    isInvoiceDetails,
    isAdmin,
    currentOrderStatus,
    productSummary,
    orderId,
    orderStatus,
    invoiceNo,
    orderType,
    orderStatusDetails,
  } = props;
  const OrderCardStatus = () => {
    if (currentOrderStatus === "placed") {
      return (
        <OrderPlacedCard
          orderId={orderId}
          isAdmin={isAdmin}
          orderStatus={orderStatus}
          orderStatusDetails={orderStatusDetails}
        />
      );
    } else if (currentOrderStatus === "confirmed") {
      if (orderType == "Offline")
        return (
          <MarkAsDeliverOfflineOrder orderId={orderId} isAdmin={isAdmin} />
        );
      return <OrderPackedcard orderId={orderId} isAdmin={isAdmin} />;
    } else if (currentOrderStatus === "packed") {
      return <OrderShippedCard />;
    } else if (currentOrderStatus === "canceled") {
      return <OrderCancelled orderId={orderId} isAdmin={isAdmin} />;
    } else if (currentOrderStatus === "delivered") {
      if (!isAdmin)
        return (
          <OrderSummaryCard
            currentOrderStatus={currentOrderStatus}
            orderSummary={{ ...productSummary }}
          />
        );
      else return <></>;
    } else {
      return (
        <OrderPlacedCard
          orderId={orderId}
          isAdmin={isAdmin}
          orderStatus={orderStatus}
          orderStatusDetails={orderStatusDetails}
        />
      );
    }
  };
  const ProductAccordionItem = ({
    productKey,
    productDescription,
  }: IProductAccordionItem) => {
    return (
      <>
        <div className={styles.accordionItem}>
          <div className={styles.productKey}>{productKey}</div>
          <div
            className={`${styles.productDescriptionColor} ${
              productKey === "Supplier's contact details"
                ? styles.preLine
                : null
            }`}
          >
            {productDescription}
          </div>
        </div>
      </>
    );
  };

  const paymentStatus = props?.paidStatus
    ? props?.paidStatus
    : props?.paymentStatus;

  const orderSummary = {
    ...productSummary,
    paidStatus: paymentStatus,
  };
  return (
    <isInvoiceDetailsProvider.Provider value={isInvoiceDetails}>
      <div
        className={`${styles.buyerDetailsContainer} ${
          isAdmin ? styles.adminBuyerDetails : null
        }`}
      >
        <div className={styles.detailsContainer}>
          {isInvoiceDetails && (
            <div className={styles.invoice}>
              <div className={styles.invoiceNo}>
                Invoice no -{" "}
                {invoiceNo?.split("_")[0] + ` ` + invoiceNo?.split("_")[1]}
              </div>
              <div
                className={
                  paymentStatus === constants.ordersListing.PAID
                    ? styles.paid
                    : styles.unpaid
                }
              >
                {paidStatus}
              </div>
            </div>
          )}
          <div className={styles.productInformationGrid}>
            {buyerDetails &&
              buyerDetails.map((buyerDetail: any) => (
                <ProductAccordionItem
                  key={`Item-${buyerDetail?.productKey}`}
                  productKey={buyerDetail?.productKey}
                  productDescription={buyerDetail?.productDescription}
                />
              ))}
          </div>
        </div>
        {
          <OrderSummaryCard
            orderSummary={orderSummary}
            currentOrderStatus={currentOrderStatus}
          />
        }
        {currentOrderStatus !== "delivered" && <OrderCardStatus />}
      </div>
    </isInvoiceDetailsProvider.Provider>
  );
};

export default BuyerDeliveryDetails;
