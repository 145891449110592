import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import styles from "../AlgoliaSearch.module.scss";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import { IAlgoliaSearchResults } from "../algoliaSearch.type";

type ResultsTableProps = {
  columns: GridColDef[];
  reportData: IAlgoliaSearchResults[];
  loading?: boolean;
};

function ResultsTable({ columns, reportData, loading }: ResultsTableProps) {
  const [page, setPage] = useState<number>(1);
  const [inputValue, setInputValues] = useState<string>("");
  const [lookupKey, setLookupKey] =
    useState<keyof Pick<IAlgoliaSearchResults, "query" | "supplier">>("query");
  const reportDataFiltered = reportData.filter((item) =>
    item[lookupKey].toLowerCase().includes(inputValue.toLowerCase())
  );

  useEffect(() => {
    setPage(1);
    setInputValues("");
  }, [loading]);

  const handleInputChange = (e: string) => {
    setInputValues(e);
    setPage(1);
  };

  return (
    <section className={styles.tableContainer}>
      <Grid
        flexDirection="row"
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ marginBottom: "8px", backgroundColor: "transparent" }}
      >
        <Grid item display="flex" flexDirection="row" gap={"4px"}>
          <input
            placeholder="filter by"
            onChange={(e) => handleInputChange(e.target.value)}
            value={inputValue}
            className={styles.inputFieldWrap}
          />
          <div className={styles.lookupDropDown}>
            <Dropdown
              id="123"
              label=""
              options={[
                {
                  value: "Query",
                  payloadValue: "query",
                },
                {
                  value: "Supplier",
                  payloadValue: "supplier",
                },
              ]}
              defaultLabel={
                { supplier: "Supplier", query: "Query" }[lookupKey] as string
              }
              onChange={(e: any) => {
                setLookupKey(e.payloadValue);
              }}
              minWidth="150px"
            />
          </div>
        </Grid>
        <div className={styles.paginationContainer}>
          <PaginationComponent
            count={Math.ceil(reportDataFiltered.length / 10)}
            handlePagination={(_, page) => {
              setPage(page);
            }}
            page={page}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </Grid>
      <DataGrid
        rows={reportDataFiltered.slice(
          10 * (page - 1),
          10 * page > reportDataFiltered.length
            ? reportDataFiltered.length
            : 10 * page
        )}
        columns={columns}
        hideFooterPagination
        hideFooter
        loading={loading}
        classes={{
          overlayWrapper: styles.overlayWrapper,
        }}
      />
    </section>
  );
}

export default ResultsTable;
