import { useEffect, useState } from "react";
import styles from "./inputField.module.scss";

const InputField = ({
  label,
  customClass,
  placeholder,
  register,
  onChange,
  type,
  errorMessage,
  id,
  icon,
  width,
  height,
  isTextarea = false,
  disabled,
  borderLeftRemove,
  borderRightRemove,
  maxWidth,
  minWidth,
  value,
  editForm,
  autoFocus,
  removeBorder,
  discountText,
  autoComplete = true,
  onFocus,
  checkEmailIsExist,
  enterCall,
  checkEmail,
  handleKeyPress,
  tabIndex = 0,
  textAreaClass,
  inputWrapClass,
  // resetForm,
  // selectedSuggestedProduct,
  endAdornment,
  handleEndAdorment,
}: any) => {
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      const activeElement = document.activeElement as
        | HTMLInputElement
        | HTMLTextAreaElement;
      if (
        activeElement &&
        (activeElement.type === "number" ||
          activeElement.classList.contains("noscroll"))
      ) {
        // activeElement.blur(); required when the scroll needs to be disabled
      }
    });
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // restrecting the password field from accepting the spaces
    if (type === "number" && event.code === "KeyE") {
      event.preventDefault();
    }
    if (
      event.code === "Enter" ||
      (type === "password" && event.code === "Space")
    ) {
      event.preventDefault();
    }
  };
  return (
    <>
      <div
        className={`${styles.inputLabelStyles} ${customClass} ${
          errorMessage ? styles.errorStyle : null
        } ${disabled ? styles.disabledIbanLabel : ""}
        ${discountText ? styles.discountText : ""}
          `}
      >
        {label}
      </div>
      <div
        style={{
          width: width,
          height: height,
          minWidth: minWidth ? `${minWidth}` : "auto",
          maxWidth: maxWidth ? `${maxWidth}` : "400px",
          border: removeBorder && "0",
          borderRight: borderRightRemove && "none",
          borderTopRightRadius: borderRightRemove && "0",
          borderBottomRightRadius: borderRightRemove && "0",
          borderTopLeftRadius: borderLeftRemove && "0",
          borderBottomLeftRadius: borderLeftRemove && "0",
          borderLeft: borderLeftRemove && "none",
        }}
        className={`${styles.inputFieldWrap} ${
          disabled ? styles.disabledIban : ""
        } ${errorMessage ? styles.errorStyle : null} ${
          type === "password" && styles.passwordField
        } ${editForm ? styles.editFormStyles : ""} ${inputWrapClass}`}
      >
        {icon ? (
          <div
            className={`${endAdornment ? styles.resetFieldIcon : styles.icon}`}
            onClick={handleEndAdorment && handleEndAdorment}
            // id === "productName" && selectedSuggestedProduct && resetForm()
          >
            <img src={icon} alt="icon" />
          </div>
        ) : (
          <></>
        )}
        {isTextarea ? (
          <textarea
            value={value}
            data-testid={id}
            className={`${styles.inputField} ${styles.textarea} ${
              editForm ? styles.editFormInputStyles : ""
            } ${textAreaClass}`}
            {...register}
            placeholder={placeholder}
            onChange={(event: any) => {
              onChange(event.target.value?.trimStart());
            }}
            tabIndex={tabIndex}
          />
        ) : (
          <input
            onKeyDownCapture={(e) => {
              if (enterCall && e?.code === "Enter") {
                handleKeyPress(e);
              }
            }}
            value={value}
            type={
              type === "password" ? (!showPassword ? "password" : "text") : type
            }
            data-testid={id}
            className={`${styles.inputField}${
              editForm ? styles.editFormInputStyles : ""
            }`}
            {...register}
            placeholder={placeholder}
            onChange={(event: any) => {
              onChange(event.target.value?.trimStart());
            }}
            disabled={disabled}
            autoFocus={autoFocus ?? false}
            autoComplete={autoComplete ? "on" : "new-password"}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            onBlur={() => checkEmail && checkEmailIsExist()}
            tabIndex={tabIndex}
            step={type === "number" ? "any" : null}
          />
        )}
        {type === "password" ? (
          <div
            data-testid="eyeIcon"
            className={`${styles.passwordIcon} ${
              showPassword ? styles.show : styles.hide
            }`}
            onClick={() => setShowPassword(!showPassword)}
          ></div>
        ) : (
          <></>
        )}
      </div>
      {errorMessage && width !== "330px" ? (
        <div className={styles.errorMessage}>{errorMessage.toString()}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default InputField;
