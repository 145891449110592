import { Avatar, Badge, Divider } from "@mui/material";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenContext } from "../../../App";
import logo from "../../../assets/images/Logo.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import accountDetailsIcon from "../../../assets/images/account-details.svg";
import catalogueIcon from "../../../assets/images/catalogue.svg";
// import adminCatalogueIcon from "../../../assets/images/admin-catalogue.svg";
import dashboardIcon from "../../../assets/images/dashboard.svg";
// import adminDashboardIcon from "../../../assets/images/admindashboard.svg";
// import helpIcon from "../../../assets/images/help.svg";
import invoiceIcon from "../../../assets/images/invoice.svg";
// import adminInvoiceIcon from "../../../assets/images/admin-invoice.svg";
import logoutIcon from "../../../assets/images/logout.svg";
// import adminLogoutIcon from "../../../assets/images/admin-logout.svg";
import marketplaceIcon from "../../../assets/images/marketplace.svg";
import moreAccountDetailsIcon from "../../../assets/images/more-acc-details.svg";
import moreHelpIcon from "../../../assets/images/more-help-circle.svg";
import moreLogoutIcon from "../../../assets/images/more-logout.svg";
import moreMyProfileIcon from "../../../assets/images/more-my-profile.svg";
import morePreferencesIcon from "../../../assets/images/more-preferences.svg";
import moreReferAFriendIcon from "../../../assets/images/more-refer-a-friend.svg";
import moreIcon from "../../../assets/images/more.svg";
import notificationIcon from "../../../assets/images/notification.svg";
import ordersIcon from "../../../assets/images/orders.svg";
// import adminOrdersIcon from "../../../assets/images/admin-orders.svg";
// import preferencesIcon from "../../../assets/images/preferences.svg";
import myProfileIcon from "../../../assets/images/profile.svg";
// import referAFriendIcon from "../../../assets/images/refer-a-friend.svg";
import selectedAccountDetailsIcon from "../../../assets/images/select-account-details.svg";
// import selectedHelpIcon from "../../../assets/images/select-help.svg";
import selectedLogoutIcon from "../../../assets/images/select-logout.svg";
import selectedNotificationIcon from "../../../assets/images/select-notification.svg";
// import selectedPreferencesIcon from "../../../assets/images/select-preferences.svg";
import selectedMyProfileIcon from "../../../assets/images/select-profile.svg";
// import selectedReferAFriendIcon from "../../../assets/images/select-refer-friend.svg";
import selectedCatalogueIcon from "../../../assets/images/selected-catalogue.svg";
// import selectedAdminCatalogueIcon from "../../../assets/images/admin-selected-catalogue.svg";
import selectedDashboardIcon from "../../../assets/images/selected-dashboard.svg";
// import selectedAdminDashboardIcon from "../../../assets/images/admin-selected-dashboard.svg";
import selectedInvoiceIcon from "../../../assets/images/selected-invoice.svg";
// import selectedAdminInvoiceIcon from "../../../assets/images/admin-selected-invoice.svg";
import selectedMarketplaceIcon from "../../../assets/images/selected-marketplace.svg";
import selectedMoreIcon from "../../../assets/images/selected-more.svg";
import selectedOrdersIcon from "../../../assets/images/selected-order.svg";
// import selectedAdminOrdersIcon from "../../../assets/images/admin-selected-order.svg";
import selectedSupplierIcon from "../../../assets/images/selected-suppliers.svg";
import supplierIcon from "../../../assets/images/suppliers.svg";
import { NavLink } from "../../../models/ISideNav";
import { AccountDetails } from "../../../pages/supplier/accountDetails/AccountDetails";
import { logoutUser } from "../../../reducerSlices/loginInSlice";
import { constants } from "../../constants/constants";
import { routeConfig } from "../../constants/routeConfig";
import MyProfile from "../../pages/myProfile/MyProfile";
import { SideDrawer } from "../SideDrawer/SideDrawer";
import UserSwitch from "../userSwitch/UserSwitch";
import Styles from "./SideNav.module.scss";
import { setIsBusinessSkipped } from "../../../reducerSlices/headerSlice";
// import { useAxios } from "../../../services/useAxios";
// import { ToastNotification } from "../toastNotification/ToastNotification";
import inactiveReportsIcon from "../../../assets/images/inactive-reports-icon.svg";
import activeReportsIcon from "../../../assets/images/active-reports-icon.svg";
import ReferAFriend from "../../../pages/buyer/login/referAFriend/ReferAFriend";
import PopupView from "../popupView/PopupView";
import { setReferAFriendState } from "../../../reducerSlices/referAFriendSlice";
import { setSearchValue } from "../../../reducerSlices/headerSlice";
import { setPage } from "../../../reducerSlices/selectedPageSlice";
import adminUser from "../../../assets/images/adminSideNAv/users.svg";
import adminUserActive from "../../../assets/images/adminSideNAv/usersActive.svg";
import adminInvoice from "../../../assets/images/adminSideNAv/Invoice.svg";
import adminInvoiceActive from "../../../assets/images/adminSideNAv/invoiceActive.svg";
import adminManageCatalogue from "../../../assets/images/adminSideNAv/catalogue.svg";
import adminCatalogueActive from "../../../assets/images/adminSideNAv/catalogueActive.svg";
import productApproval from "../../../assets/images/adminSideNAv/productsApproval.svg";
import productApprovalActive from "../../../assets/images/adminSideNAv/productsApprovalActive.svg";
import buyerApproval from "../../../assets/images/adminSideNAv/buyerApproval.svg";
import buyerApprovalActive from "../../../assets/images/adminSideNAv/buyerApprovalActive.svg";
import supplierApproval from "../../../assets/images/adminSideNAv/supplierApproval.svg";
import supplierApprovalActive from "../../../assets/images/adminSideNAv/supplierApprovalActive.svg";
import adminManageOrders from "../../../assets/images/adminSideNAv/manageOrders.svg";
import adminManageOrdersActive from "../../../assets/images/adminSideNAv/manageOrdersActive.svg";
import adminLogout from "../../../assets/images/adminSideNAv/logout.svg";
import adminLogoutActive from "../../../assets/images/adminSideNAv/logoutActive.svg";
import adminReportsIconActive from "../../../assets/images/active-admin-reports-icon.svg";
import adminReportsIcon from "../../../assets/images/inactive-reports-admin-icon.svg";
import Notifications from "../../../pages/supplier/notifications/notifications";
import specialPricingActive from "../../../assets/images/specialPricingActive.svg";
import specialPricingSupplier from "../../../assets/images/specialPricingSupplier.svg";
import specialPricingInactive from "../../../assets/images/specialPricingInactive.svg";
import specialPricingSupplierInactive from "../../../assets/images/specialPricingSupplierInactive.svg";
import { ToastNotification } from "../toastNotification/ToastNotification";
import { useAxios } from "../../../services/useAxios";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";

const adminLinks = [
  {
    name: "Users",
    icon: adminUser,
    selectedIcon: adminUserActive,
    link: routeConfig.adminAddBuyer,
    id: "users",
    roles: ["b", "s"],
  },
  {
    name: "Offline Suppliers",
    icon: adminUser,
    selectedIcon: adminUserActive,
    link: routeConfig.offlineSuppliers,
    id: "offline-suppliers",
    roles: ["b", "s"],
  },

  {
    name: "Manage Catalogue",
    icon: adminManageCatalogue,
    selectedIcon: adminCatalogueActive,
    link: routeConfig.adminManageCatalogue,
    id: "manage-catalogue",
  },
  {
    name: "Products Approval",
    icon: productApproval,
    selectedIcon: productApprovalActive,
    link: routeConfig.adminProductApproval,
    id: "products-approval",
  },
  {
    name: "Buyer Approval",
    icon: buyerApproval,
    selectedIcon: buyerApprovalActive,
    link: routeConfig.buyerBusinessApprovalPage,
    id: "buyer-approval",
  },
  {
    name: "Supplier Approval",
    icon: supplierApproval,
    selectedIcon: supplierApprovalActive,
    link: routeConfig.supplierBusinessApprovalPage,
    id: "supplier-approval",
  },
  {
    name: "Special Pricing",
    icon: specialPricingInactive,
    selectedIcon: specialPricingActive,
    link: routeConfig.adminSpecialPricing,
    id: "special-pricing",
  },
  {
    name: "Manage Orders",
    icon: adminManageOrders,
    selectedIcon: adminManageOrdersActive,
    link: routeConfig.adminManageOrders,
    id: "manage-admin-orders",
  },
  {
    name: "Invoices",
    icon: adminInvoice,
    selectedIcon: adminInvoiceActive,
    link: routeConfig.adminManageInvoices,
    id: "manage-admin-invoices",
  },
  {
    name: "Report",
    icon: adminReportsIcon,
    selectedIcon: adminReportsIconActive,
    link: routeConfig.adminReport,
    id: constants.navLinksId.report,
  },
  {
    name: "All Customers",
    icon: adminUser,
    selectedIcon: adminUserActive,
    link: routeConfig.adminCustomersList,
    id: "customers",
  },
  {
    name: "Create Finance",
    icon: adminUser,
    selectedIcon: adminUserActive,
    link: routeConfig.createFinanceRequest,
    id: "create-finance",
  },
  {
    name: "Algolia Search",
    icon: adminReportsIcon,
    selectedIcon: adminReportsIconActive,
    link: routeConfig.algoliaSearch,
    id: "algolia-search",
  },
  {
    name: "Add Favourite",
    icon: adminReportsIcon,
    selectedIcon: adminReportsIconActive,
    link: routeConfig.addFavourite,
    id: "add-favourite",
  },
  {
    name: "Logout",
    icon: adminLogout,
    selectedIcon: adminLogoutActive,
    link: "",
    id: constants.navLinksId.logout,
  },
];
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "&.MuiTooltip-popper": {
    left: "168px !important",
    top: "-133px !important",
    maxWidth: "251px",
    width: "251px",
    "& .MuiTooltip-tooltip": {
      width: "100%",
      padding: "12px 0 20px !important",
      background: "#fff !important",
      boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.15)",
      borderRadius: "6px",
      border: "none !important",
    },
    "& .MuiTooltip-arrow": {
      width: "3.6em !important",
      height: "1.29em !important",
      left: "-49.9% !important",
      top: "33% !important",
      transform: "translate3d(98.5px, 0px, 0px) rotate(270deg) !important",
      "&:before": {
        background: "#fff !important",
      },
    },
  },
}));

const BadgeNotifications = styled(Badge)`
  .MuiBadge-badge {
    min-width: 5px;
    border-radius: 50%;
    background: #fff;
    color: #ec187b;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    height: 16px;
    margin: 5px;
  }
`;

const AvatarContainer = styled("div")`
  position: relative;
  padding: 0 20px;
  z-index: 1;
  & .MuiAvatar-root {
    height: 53px;
    width: 53px;
    background: #ec187b;
  }
  .userName {
    color: #fff;
    font-family: "UrbanistLight";
    font-size: 16px;
    line-height: normal;
    margin-top: 14px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 129px;
    width: 100%;
    white-space: nowrap;
  }
`;

const SideNav: React.FC<{
  userType: string;
  setUserType: any;
  selectedId?: string;
  onRouteChange: (value: string) => void;
  roles: string[];
}> = ({ userType, setUserType, selectedId, onRouteChange, roles }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const [selected, setSelected] = useState(selectedId ?? "dashboard");
  const showReferAFriend = useSelector(
    (state: any) => state?.setReferAFriendState?.popupState
  );
  const [open, setOpen] = useState(false);
  let formattedRoleKey = "N/A";
  const profile = JSON.parse(localStorage.getItem("loggedInUser") || "{}");
  if (profile?.business_details?.[0]?.role_key) {
    const roleKeyParts = profile.business_details?.[0].role_key.split("-");
    const roleKeyWithoutFirstPart = roleKeyParts.slice(1).join(" ");
    const roleType =
      profile.business_details?.[0]?.role_type.charAt(0).toUpperCase() +
      profile.business_details?.[0]?.role_type.slice(1);
    formattedRoleKey =
      roleType +
      " " +
      roleKeyWithoutFirstPart.charAt(0).toUpperCase() +
      roleKeyWithoutFirstPart.slice(1);
  }
  const profileImageState = useSelector(
    (state: any) => state?.setProfileImage?.profileImage
  );

  const navLinksSupplier: NavLink[] = [
    {
      name: "Dashboard",
      icon: dashboardIcon,
      selectedIcon: selectedDashboardIcon,
      link: routeConfig.supplierDashboard,
      id: constants.navLinksId.dashboard,
    },
    {
      name: "Orders",
      icon: ordersIcon,
      selectedIcon: selectedOrdersIcon,
      link: routeConfig.orderListingCatalogue,
      id: constants.navLinksId.orders,
    },
    {
      name: "Invoices",
      icon: invoiceIcon,
      selectedIcon: selectedInvoiceIcon,
      link: routeConfig.inVoices,
      id: constants.navLinksId.invoices,
    },
    {
      name: "Catalogue",
      icon: catalogueIcon,
      selectedIcon: selectedCatalogueIcon,
      link: routeConfig.productListingCatalogue,
      id: constants.navLinksId.catalogue,
    },
    //Commented because it was creating height issue to the side Nav
    {
      name: "Notification",
      icon: notificationIcon,
      selectedIcon: selectedNotificationIcon,
      link: "notifications",
      id: constants.navLinksId.notification,
    },
    // {
    //   name: "Preferences",
    //   icon: preferencesIcon,
    //   selectedIcon: selectedPreferencesIcon,
    //   link: routeConfig.loadingScreen,
    //   id: constants.navLinksId.preferences,
    // },
    {
      name: "Report",
      icon: inactiveReportsIcon,
      selectedIcon: activeReportsIcon,
      link: routeConfig.supplierReport,
      id: constants.navLinksId.report,
    },
    {
      name: "Special pricing",
      icon: specialPricingSupplierInactive,
      selectedIcon: specialPricingSupplier,
      link: routeConfig.specialPricing,
      id: constants.navLinksId.special_pricing,
    },

    {
      name: "Account details",
      icon: accountDetailsIcon,
      selectedIcon: selectedAccountDetailsIcon,
      link: "account-details",
      id: constants.navLinksId.account_details,
    },
    {
      name: "Preferences",
      link: routeConfig.supplierPreferences,
      id: constants.navLinksId.preferences,
      icon: morePreferencesIcon,
      selectedIcon: morePreferencesIcon,
    },
    {
      name: "My profile",
      icon: myProfileIcon,
      selectedIcon: selectedMyProfileIcon,
      link: routeConfig.myProfile,
      id: constants.navLinksId.my_profile,
    },
    // {
    //   name: "Refer a friend",
    //   icon: referAFriendIcon,
    //   selectedIcon: selectedReferAFriendIcon,
    //   link: routeConfig.referAFriend,
    //   id: constants.navLinksId.refer_a_friend,
    // },
    // {
    //   name: "Help & support",
    //   icon: helpIcon,
    //   selectedIcon: selectedHelpIcon,
    //   link: routeConfig.loadingScreen,
    //   id: constants.navLinksId.help_support,
    // },
    {
      name: "Logout",
      icon: logoutIcon,
      selectedIcon: selectedLogoutIcon,
      link: "",
      id: constants.navLinksId.logout,
    },
  ];
  const navLinksBuyer: NavLink[] = [
    {
      name: "Dashboard",
      icon: dashboardIcon,
      selectedIcon: selectedDashboardIcon,
      link: routeConfig.dashboard,
      id: constants.navLinksId.dashboard,
    },
    {
      name: "Marketplace",
      icon: marketplaceIcon,
      selectedIcon: selectedMarketplaceIcon,
      link: routeConfig.marketPlace,
      id: constants.navLinksId.marketplace,
    },
    {
      name: "Orders",
      icon: ordersIcon,
      selectedIcon: selectedOrdersIcon,
      link: routeConfig.buyerOrdersListing,
      id: constants.navLinksId.orders,
    },
    {
      name: "Invoices",
      icon: invoiceIcon,
      selectedIcon: selectedInvoiceIcon,
      link: routeConfig.buyerInvoices,
      id: constants.navLinksId.invoices,
    },
    {
      name: "Suppliers",
      icon: supplierIcon,
      selectedIcon: selectedSupplierIcon,
      link: routeConfig.buyerAddNewSupplier,
      id: constants.navLinksId.suppliers,
    },
    {
      name: "Special Pricing",
      icon: specialPricingSupplier,
      selectedIcon: specialPricingSupplier,
      link: routeConfig.adminSpecialPricing,
      id: "special-pricing",
    },
    {
      name: "Notifications",
      icon: notificationIcon,
      selectedIcon: selectedNotificationIcon,
      link: routeConfig.buyerLoadingScreen,
      id: constants.navLinksId.notifications,
    },
    {
      name: "More",
      icon: moreIcon,
      selectedIcon: selectedMoreIcon,
      link: "/more",
      id: constants.navLinksId.more,
    },
  ];
  const moreBuyerLinks: NavLink[] = [
    {
      name: "View profile",
      link: routeConfig.buyerLoadingScreen,
      id: constants.navLinksId.view_profile,
      icon: moreMyProfileIcon,
    },
    {
      name: "Preferences",
      link: routeConfig.buyerPreferences,
      id: constants.navLinksId.preferences,
      icon: morePreferencesIcon,
      selectedIcon: morePreferencesIcon,
    },
    {
      name: "Refer a Friend",
      link: routeConfig.buyerLoadingScreen,
      id: constants.navLinksId.refer_a_friend,
      icon: moreReferAFriendIcon,
    },
    {
      name: "Account details",
      link: routeConfig.buyerLoadingScreen,
      id: constants.navLinksId.account_details,
      icon: moreAccountDetailsIcon,
    },
    {
      name: "Help & support",
      link: routeConfig.buyerLoadingScreen,
      id: constants.navLinksId.help_support,
      icon: moreHelpIcon,
    },
    {
      name: "Logout",
      link: "/logout",
      id: constants.navLinksId.logout,
      icon: moreLogoutIcon,
    },
  ];
  const [isAcntDetails, setIsAcntDetails] = useState(false);
  const [ismyProfile, setViewProfile] = useState(false);
  const [isdrawer, setIsDrawer] = React.useState({
    right: true,
  });
  const [isProfiledrawer, setIsProfileDrawer] = React.useState({
    right: true,
  });

  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );

  // const [customerId] = useState(
  //   loggedInUser &&
  //     loggedInUser?.typeOfUser[0] !== "admin" &&
  //     loggedInUser?.business_details?.[0]?.customer_id
  // );
  // const [businessId] = useState(
  //   loggedInUser &&
  //     loggedInUser?.typeOfUser[0] !== "admin" &&
  //     loggedInUser?.business_details?.[0]?.business_setup_id
  // );

  const [toastData, setToastData] = useState(constants.defaultToastData);
  // const [fetchedData, setFetchedData] = useState<any>({});
  // const fetchCTACardStatus = useAxios({
  //   axiosParams: {
  //     url: `store/onboarding/supplier/${customerId}/${businessId}`,
  //   },
  //   method: "GET",
  // });
  // const fetchCTACardStatusAPI = async () => {
  //   if (customerId && businessId) {
  //     const response: any = await fetchCTACardStatus.fetchData();
  //     if (response && response?.status === "SUCCESS") {
  //       setFetchedData(response?.data);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchCTACardStatusAPI();
  // }, []);
  const logoutApi = useAxios({
    axiosParams: {
      url: "store/logout",
    },
    method: "POST",
  });
  const logout = async () => {
    dispatch(updateIsLoading(true));
    const logoutResponse = (await logoutApi.fetchData()) as any;
    if (logoutResponse?.status === 9004) {
      contextValue.removeSessionToken();
      dispatch(logoutUser());
      localStorage.removeItem("loggedInUser");
      dispatch(setIsBusinessSkipped("false"));
      localStorage.clear();
      dispatch(setSearchValue(""));
    }
    dispatch(updateIsLoading(false));
  };
  const notificationRef = useRef<HTMLDivElement | any>(null);
  const [prevSelected, setPrevSelected] = useState(selected);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();
  const contextValue: any = useContext(TokenContext);
  const handleLinkClick = async (each: any) => {
    onRouteChange(each?.link);
    dispatch(setSearchValue(""));
    setSelected(each?.id);
    dispatch(setPage({ type: "reset", index: 1 }));
    if (userType !== "buyer") {
      if (
        each?.id === constants.navLinksId.catalogue &&
        localStorage.getItem("is_approved") !== "true"
      ) {
        setToastData({
          message:
            "Business setup request is under review. Please wait until it gets approved.",
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      } else if (each?.id === constants.navLinksId.account_details) {
        window.history.pushState(null, "");
        setIsAcntDetails(true);
        setIsDrawer({ ...isdrawer, right: true });
        setShowNotifications(false);
      } else if (each?.id === constants.navLinksId.my_profile) {
        window.history.pushState(null, "");
        setViewProfile(true);
        setIsProfileDrawer({ ...isdrawer, right: true });
        setShowNotifications(false);
      } else if (each?.id === constants.navLinksId.refer_a_friend) {
        window.history.pushState(null, "");
        dispatch(setReferAFriendState(true));
        setShowNotifications(false);
      } else if (each?.id === "notification") {
        // window.history.pushState(null, "");
        // navigate(each?.link);
        setShowNotifications(true);
        // dispatch(setReferAFriendState(true));
      } else if (each?.id === constants.navLinksId.preferences) {
        if (loggedInUser && localStorage.getItem("is_approved") !== "true") {
          setToastData({
            message:
              "Business setup request is under review. Please wait until it gets approved.",
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
        } else {
          setPrevSelected(each?.id);
          navigate(each?.link);
          setShowNotifications(false);
        }
      } else if (each?.id !== "logout") {
        setPrevSelected(each?.id);
        navigate(each?.link);
        setShowNotifications(false);
      }
    } else if (userType === "buyer") {
      if (each?.link === "/more") {
        return;
      }
      setPrevSelected(each?.id);
      navigate(each?.link);
    }
    if (each?.id === "logout") {
      logout();
    }
  };
  useEffect(() => {
    if (showNotifications) {
      setShowNotifications(false);
    }
  }, [currentPath]);

  window.onpopstate = () => {
    if (ismyProfile) setViewProfile(false);
    if (isAcntDetails) setIsAcntDetails(false);
  };

  const handleShowPopupView = () => {
    dispatch(setReferAFriendState(false));
  };

  useEffect(() => {
    if (isAcntDetails === true && isdrawer["right"] === false) {
      setSelected(prevSelected);
    }
    if (ismyProfile === true && isProfiledrawer["right"] === false) {
      setSelected(prevSelected);
    }
  }, [isAcntDetails, ismyProfile, isdrawer["right"], isProfiledrawer["right"]]);

  const getNameInitials = (name: string) => {
    const words = name.trim().split(" ");

    if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    } else if (words.length >= 2) {
      const initials = words
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
      return initials.substring(0, 2);
    } else {
      return "";
    }
  };

  const username = loggedInUser?.first_name
    ? `${loggedInUser?.first_name} ${loggedInUser?.last_name}`
    : "User";

  const avatarInitials = getNameInitials(username);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (isProfiledrawer.right === false) {
  //     window.location.reload();
  //   }
  // }, [isProfiledrawer]);
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [isBackButton, setIsBackButton] = useState(false);
  const [backNavigate, setBackNavigation] = useState(false);
  const handleBackState = (state: boolean) => {
    setBackNavigation(state);
    setIsBackButton(false);
  };
  const [image, setImage] = useState(loggedInUser?.profile_image);

  useEffect(() => {
    if (profileImageState) {
      setImage(profileImageState);
    } else {
      setImage("");
    }
  }, [profileImageState, image]);

  const [profileHeader, setProfileHeader] = useState("My Profile");
  const tooltipRef = useRef<HTMLDivElement | any>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        handleTooltipClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    handleBackState(true);
  }, [isProfiledrawer?.right]);

  useEffect(() => {
    if (tooltipRef.current) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          tooltipRef.current &&
          !tooltipRef.current.contains(event.target as Node) &&
          notificationRef.current &&
          !notificationRef.current.contains(event.target as Node)
        ) {
          handleTooltipClose();
          setShowNotifications(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [tooltipRef.current, notificationRef.current]);

  return (
    <div className={Styles.sidenavContainer}>
      <div className={Styles.logoContainer}>
        <img src={logo} alt="logo" />
      </div>
      <AvatarContainer className={Styles.avatarContainer}>
        {image ? (
          <Avatar alt="user-name" src={image} data-testid="avatar" />
        ) : (
          <Avatar data-testid="avatar-initials">{avatarInitials}</Avatar>
        )}
        <div className="userName" title={username}>
          {username && username}
        </div>
        <div className={Styles.sideNavRoleText}>
          {formattedRoleKey ?? "N/A"}
        </div>
      </AvatarContainer>
      {/* temporary condition as this is hidden in UI */}
      {roles.length > 0 && false && (
        <UserSwitch
          userType={userType}
          setUserType={setUserType}
          data-testid="user-switch"
        />
      )}
      <Divider className={Styles.divider} />
      <div className={Styles.navLinks}>
        {(userType === "buyer" ? navLinksBuyer : navLinksSupplier).map(
          (eachLink) => (
            <>
              {eachLink.id === "notification" ? (
                <>
                  <div
                    id={eachLink.id}
                    className={`${Styles.link} ${
                      selected === eachLink.id ? Styles.selected : ""
                    } ${Styles.notificationsLink}`}
                    key={eachLink.id}
                    onClick={() => {
                      handleLinkClick(eachLink);
                    }}
                    ref={tooltipRef}
                    data-testid={`nav-link-${eachLink.id}`}
                  >
                    <div
                      className={`${Styles.notificationsArrowIndicator} ${
                        showNotifications ? Styles.arrowActive : ""
                      }`}
                    ></div>
                    <BadgeNotifications
                      badgeContent={0}
                      data-testid="badge-notifications"
                    >
                      <img
                        src={
                          showNotifications
                            ? eachLink.selectedIcon
                            : eachLink.icon
                        }
                        alt={eachLink.name}
                      />
                    </BadgeNotifications>
                    <p>{eachLink.name}</p>
                  </div>
                </>
              ) : (
                <div
                  id={eachLink.id}
                  className={`${Styles.link} ${
                    selected === eachLink.id ||
                    (isAcntDetails &&
                      eachLink.id === constants.navLinksId.account_details)
                      ? Styles.selected
                      : ""
                  }`}
                  key={eachLink.id}
                  onClick={() => handleLinkClick(eachLink)}
                  data-testid={`nav-link-${eachLink.id}`}
                >
                  <img
                    src={
                      currentPath.includes(eachLink.id) ||
                      (isAcntDetails &&
                        eachLink.id === constants.navLinksId.account_details)
                        ? eachLink.selectedIcon
                        : eachLink.icon
                    }
                    alt={eachLink.name}
                  />

                  <p>{eachLink.name}</p>
                  {eachLink.id === "more" && (
                    <HtmlTooltip
                      data-testid="more-tooltip"
                      arrow
                      className={Styles.moreTooltip}
                      title={
                        <>
                          {moreBuyerLinks.map((eachLink) => (
                            <div
                              data-testid={`more-link-${eachLink.id}`}
                              className={Styles.moreLink}
                              key={eachLink.id}
                              onClick={() => handleLinkClick(eachLink)}
                            >
                              <img
                                src={
                                  currentPath.includes(eachLink.id)
                                    ? eachLink.selectedIcon
                                    : eachLink.icon
                                }
                                alt={eachLink.name}
                              />
                              <div>{eachLink.name}</div>
                            </div>
                          ))}
                        </>
                      }
                      open={open}
                    >
                      <button
                        data-testid="more-btn"
                        className={Styles.tooltipBtn}
                        onClick={handleTooltipOpen}
                        ref={tooltipRef}
                      >
                        {eachLink.name}
                      </button>
                    </HtmlTooltip>
                  )}
                </div>
              )}
              {eachLink.id === "my-profile" && (
                <Divider className={Styles.divider} />
              )}
              {eachLink.id === constants.navLinksId.account_details &&
                isAcntDetails && (
                  <AccountDetails setIsAcntDetails={setIsAcntDetails} />
                )}
              {eachLink.id === constants.navLinksId.my_profile &&
                ismyProfile &&
                isProfiledrawer?.["right"] && (
                  <>
                    <SideDrawer
                      backNavigate={isBackButton}
                      setBackNavigation={handleBackState}
                      isdrawer={isProfiledrawer}
                      setIsDrawer={setIsProfileDrawer}
                      heading={profileHeader}
                      childComponent={
                        <MyProfile
                          backNavigation={backNavigate}
                          setIsBack={setIsBackButton}
                          setBackNavigation={setBackNavigation}
                          setProfileHeader={setProfileHeader}
                          setIsDrawer={setIsProfileDrawer}
                        />
                      }
                    />
                  </>
                )}

              {eachLink.id === constants.navLinksId.refer_a_friend &&
                showReferAFriend && (
                  <PopupView
                    childComponent={ReferAFriend}
                    hideHeader={true}
                    width={890}
                    height={431}
                    handlePopup={handleShowPopupView}
                  ></PopupView>
                )}
            </>
          )
        )}
      </div>
      {/* {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )} */}
      {showNotifications && (
        <div ref={notificationRef}>
          <Notifications />
        </div>
      )}
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SideNav;

export const AdminSideNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contextValue: any = useContext(TokenContext);
  const [loggedInUser] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );

  const username =
    loggedInUser && loggedInUser?.first_name
      ? `${loggedInUser?.first_name} ${loggedInUser?.last_name}`
      : loggedInUser.email;
  const getNameInitials = (name: string) => {
    const words = name.trim().split(" ");

    if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    } else if (words.length >= 2) {
      const initials = words
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
      return initials.substring(0, 2);
    } else {
      return "";
    }
  };
  const logoutApi = useAxios({
    axiosParams: {
      url: "admin/logout",
    },
    method: "POST",
  });
  const logout = async () => {
    dispatch(updateIsLoading(true));
    const logoutResponse = (await logoutApi.fetchData()) as any;
    if (logoutResponse?.status === 9004) {
      contextValue.removeSessionToken();
      dispatch(logoutUser());
      localStorage.removeItem("loggedInUser");
      dispatch(setIsBusinessSkipped("false"));
      localStorage.clear();
    }
    dispatch(updateIsLoading(false));
  };
  const avatarInitials = getNameInitials(username);
  const handleLinkClick = (each: any) => {
    dispatch(setPage({ type: "reset", index: 1 }));
    if (each?.id === "logout") {
      logout();
    }
    if (each?.id === "manage-admin-invoices") {
      navigate(each?.link, { state: { routeId: "manage-admin-invoices" } });
    } else if (each?.id !== "logout") {
      console.log(each.link, "ppppppppppppppppp");
      navigate(each?.link);
    }
  };

  return (
    <div
      className={`${Styles.sidenavContainer} ${Styles.adminSidenavContainer}`}
      style={{ height: "100vh", background: "#e7b962" }}
    >
      <div className={Styles.logoContainer}>
        <img src={logo} alt="logo" />
      </div>
      <AvatarContainer className={Styles.avatarContainer}>
        {loggedInUser && loggedInUser?.profile_image ? (
          <Avatar
            alt="user-name"
            src={loggedInUser?.profile_image}
            data-testid="avatar"
          />
        ) : (
          <Avatar data-testid="avatar-initials">{avatarInitials}</Avatar>
        )}
        <div className="userName">{username}</div>
      </AvatarContainer>
      <Divider className={Styles.divider} />
      <div className={Styles.navLinks}>
        {adminLinks &&
          adminLinks.length > 0 &&
          adminLinks.map((eachLink) => (
            <>
              {
                <div
                  className={Styles.link}
                  key={eachLink.id}
                  onClick={() => handleLinkClick(eachLink)}
                >
                  <img
                    src={
                      currentPath.includes(eachLink.id)
                        ? eachLink.selectedIcon
                        : eachLink.icon
                    }
                    alt={eachLink.name}
                  />
                  <p>{eachLink.name}</p>
                </div>
              }
            </>
          ))}
      </div>
    </div>
  );
};
