import { Grid, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";
import InputField from "../../../common/components/formFields/inputField/inputField";
import styles from "./addFavourite.module.scss";
import { getCsvValuesByHeader, readXlsxFile } from "../../../utils/utils";
import { constants } from "../../../common/constants/constants";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { addFavouriteColumns } from "./columns";
import { ApiService } from "../algoliaSearch/customApiService/customApiService";
import {
  addFavouriteResponse,
  addFavouriteResults,
  algoliaData,
} from "./addFavourite.type";
import AddFavouriteResultsTable from "./addFavouriteTable/addFavouriteResultsTable";
import InvalidIdDownload from "./invalidIdDownload";

function AddFavourite() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [foundProducts, setFoundProducts] = useState<addFavouriteResults[]>([]);
  const [notFoundProducts, setNotFoundProducts] = useState<
    addFavouriteResults[]
  >([]);

  const apiService = new ApiService();

  const findProductById = async (objectIds: string[]) => {
    setLoading(true);
    const data = await apiService.callApi<addFavouriteResponse>({
      method: "post",
      url: "store/algolia/get-algolia-search-result?type=id",
      data: {
        objectIds: objectIds.map((id) => ({ id })),
      },
    });
    const mapProductData = (products: algoliaData[]): addFavouriteResults[] => {
      return products.map((item) => ({
        id: item.id,
        objectId: item.id,
        title: item.title ?? "N/A",
        supplier: item.supplier ?? "N/A",
        price: item?.price?.map((i) => `AED ${i}`)?.join(", ") ?? "N/A",
        packaging: item.packaging?.join(", ") ?? "N/A",
        category: item.category ?? "N/A",
        subCategory: item.subCategory ?? "N/A",
        brandName: item.brandName ?? "N/A",
      }));
    };

    const foundData: addFavouriteResults[] = mapProductData(
      data?.data?.foundProducts ?? []
    );
    const notFoundData: addFavouriteResults[] = mapProductData(
      data?.data?.notFoundProducts ?? []
    );

    setFoundProducts(foundData);
    setNotFoundProducts(notFoundData);
    setLoading(false);
    return foundData.map((item) => item.objectId);
  };

  const checkCustomerEmail = async (email: string) => {
    setLoading(true);
    const data = await apiService.callApi<string | null>({
      method: "get",
      url: `store/customer/byEmail?email=${email}`,
      data: {
        email,
      },
    });
    if (data && data.data) {
      setCustomerId(data.data);
      setEmailValid(true);
      setToastData({
        message: "Email verified",
        status: "Success",
      });
    } else {
      setToastData({
        message: "Email not found",
        status: "Error",
      });
    }
    setLoading(false);
  };

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const file = e.target.files?.[0];
    if (file) {
      const fileType = file.type;
      const onError = (error: string) => {
        setToastData({
          message: error,
          status: "Error",
        });
      };
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel" ||
        fileType === "text/csv"
      ) {
        setLoading(true);
        const rows = await readXlsxFile(file);
        const id = getCsvValuesByHeader(rows, "id", onError).data;
        const foundProductsIds = await findProductById(id);
        if (foundProductsIds.length) {
          const data = await apiService.callApi<{
            productId: string;
            message: string;
          }>({
            method: "post",
            url: `store/favourites/addBulk/${customerId}`,
            data: {
              productIds: foundProductsIds,
            },
          });
          if (data) {
            setToastData({
              message: "Products added to favourites",
              status: "Success",
            });
          }
          setLoading(false);
        } else {
          console.error("Unsupported file type");
        }
      }
    }
    e.target.value = "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkCustomerEmail(email);
  };

  return (
    <>
      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
          handleClose={() => setToastData(constants.defaultToastData)}
        />
      )}
      <Grid style={{ padding: "0px 20px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container flexDirection="column" gap="8px">
            <Grid
              item
              container
              flexDirection="column"
              style={{
                width: "fit-content",
              }}
            >
              <InputField
                placeholder="Search"
                maxWidth="100%"
                onChange={(e: any) => {
                  setSearch(e);
                }}
                value={search || ""}
              />
              <InputField
                placeholder="email"
                maxWidth="100%"
                onChange={(e: any) => {
                  setEmail(e);
                  setEmailValid(false);
                }}
                value={email || ""}
              />
            </Grid>
            <Grid
              item
              style={{
                width: "fit-content",
              }}
            >
              <button
                className={styles.commonBtn}
                type="submit"
                disabled={loading}
              >
                Validate Email
              </button>
            </Grid>
          </Grid>
        </form>
        <Grid item container gap={"8px"} paddingTop={"8px"}>
          <Grid item style={{ width: "fit-content" }}>
            <InvalidIdDownload
              data={notFoundProducts}
              disabled={!notFoundProducts.length}
            />
          </Grid>
          <Grid item style={{ width: "fit-content" }}>
            <Tooltip title="Upload a CSV of products ids to link to the customer">
              <button
                className={styles.commonBtn}
                type="button"
                onClick={handleFileUploadClick}
                disabled={loading || !emailValid}
              >
                Upload
              </button>
            </Tooltip>
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
              className={styles.commonBtn}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={onUpload}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item>
            <AddFavouriteResultsTable
              columns={addFavouriteColumns}
              data={foundProducts}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddFavourite;
