import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { addFavouriteResults } from "../addFavourite.type";
import styles from "./addFavouriteResultsTable.module.scss";

type AddFavouriteResultsTableProps = {
  columns: GridColDef[];
  data: addFavouriteResults[];
  loading?: boolean;
};

function AddFavouriteResultsTable({
  columns,
  data,
  loading,
}: AddFavouriteResultsTableProps) {
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(1);
  }, [loading]);

  return (
    <section>
      <Grid
        flexDirection="row"
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{ marginBottom: "8px", backgroundColor: "transparent" }}
      >
        <div>
          <PaginationComponent
            count={Math.ceil(data.length / 10)}
            handlePagination={(_, page) => {
              setPage(page);
            }}
            page={page}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </Grid>
      <DataGrid
        rows={data.slice(
          10 * (page - 1),
          10 * page > data.length ? data.length : 10 * page
        )}
        columns={columns}
        hideFooterPagination
        hideFooter
        loading={loading}
        className={styles.dataGridContainer}
        classes={{
          overlayWrapper: styles.overlayWrapper,
        }}
      />
    </section>
  );
}

export default AddFavouriteResultsTable;
