import { useContext } from "react";
import image from "../../../../assets/images/coins.svg";
import { isInvoiceDetailsProvider } from "../BuyerDeliveryDetails";
import styles from "./OrderSummaryCard.module.scss";
import { constants } from "../../../constants/constants";
const OrderSummaryCard = (orderSummary: any) => {
  const {
    estimatedSubtotal,
    deliverCharges,
    vatCharges,
    vatPercentage,
    estimatedAmount,
    recievedAmount,
    totalItem,
    paidStatus,
    // totalAmount,
    // modifiedAmount,
    modifiedAmount,
    modifiedSubtotal,
  } = orderSummary?.orderSummary;
  const isInvoiceDetails = useContext(isInvoiceDetailsProvider);
  // const refundAmount = Number(totalAmount) - Number(modifiedAmount);
  
  const paidAndDelivered = orderSummary.currentOrderStatus === "delivered" &&   paidStatus?.toLowerCase() === constants.ordersListing.PAID.toLowerCase()
  return (
    <>
      <section>
        <div className={styles.cardContainer}>
          <div className={styles.cardHeader}>
            Order summary ({totalItem ?? "0"})
          </div>
          <div className={styles.divider}></div>
          <div className={styles.cardDataContainer}>
            <div className={styles.carddataRow}>
              <div className={styles.labelContainer}>{paidAndDelivered ? "Subtotal" : "Estimated subtotal"}</div>
              <div className={styles.aedData}>
                <div className={styles.aedLabel}>AED</div>
                {modifiedSubtotal && modifiedSubtotal > 0
                  ? Number(modifiedSubtotal)?.toFixed(2)
                  : Number(estimatedSubtotal)?.toFixed(2)}
              </div>
            </div>
            <div className={styles.carddataRow}>
              <div className={styles.labelContainer}>Delivery charges</div>
              <div className={styles.aedData}>
                <div className={styles.aedLabel}>AED</div>
                {deliverCharges && Number(deliverCharges) !== 0
                  ? Number(deliverCharges).toFixed(2)
                  : "0.00"}
              </div>
            </div>
            <div className={styles.carddataRow}>
              <div className={styles.labelContainer}>
                VAT charges (
                {vatPercentage && vatPercentage
                  ? vatPercentage?.toString()?.replace(0, "")
                  : 0}
                %)
              </div>
              <div className={styles.aedData}>
                <div className={styles.aedLabel}>AED</div>
                {vatCharges && Number(vatCharges)?.toFixed(2)}
              </div>
            </div>
            {isInvoiceDetails && <div className={styles.divider2}></div>}
            <div className={styles.carddataRow}>
              <div className={styles.labelContainer}>
                { paidAndDelivered ? "Amount" : !isInvoiceDetails ? "Estimated amount" : "Actual amount"}
              </div>
              <div className={styles.aedData}>
                <div className={styles.aedLabel}>AED</div>

                {modifiedAmount && modifiedAmount > 0
                  ? Number(modifiedAmount)?.toFixed(2)
                  : Number(estimatedAmount)?.toFixed(2)}
              </div>
            </div>
          </div>
          <div className={styles.amountRecieved}>
            <img src={image} alt="coinimage" />
            <div>
              <div className={styles.totalAed}>
                <div className={styles.aedLabel}>AED</div>
                <div className={styles.totalAmount}>
                  {modifiedAmount && modifiedAmount > 0
                    ? Number(modifiedAmount)?.toFixed(2)
                    : Number(recievedAmount)?.toFixed(2)}
                </div>
              </div>
              <div className={styles.recievedAmount}>
                {paidStatus?.toLowerCase() === constants.ordersListing.PAID.toLowerCase()
                  ? "Received Amount"
                  : "Estimated total to pay"}
                {/* {!isInvoiceDetails ? "Recieved Amount" : "Estimated total to pay"} */}
              </div>
            </div>
          </div>
        </div>
      </section>

     
    </>
  );
};

export default OrderSummaryCard;
